import * as jsEvent from '../common/jsEvent';
import jsMediaEngineVariables from './JsMediaEngine_Variables';

const UsagePage = {
  LED: 0x08,
  TELEPHONY: 0x0b,
};

export class HIDControl {
  static async init(label, muted) {
    if (!window.navigator.hid || !window.navigator.hid.requestDevice) {
      return false;
    }
    const webHid = (this.webHid = new HID((data) => {
      switch (data.eventName) {
        case 'ondevicehookswitch':
          jsMediaEngineVariables.Notify_APPUI_SAFE(
            jsEvent.HID_STATUS_OFF_HOOK,
            data.hookStatus === 'on'
          );
          break;
        case 'ondevicemuteswitch':
          jsMediaEngineVariables.Notify_APPUI_SAFE(
            jsEvent.HID_STATUS_MUTE,
            data.isMute
          );
          break;
        default:
          break;
      }
    }));
    return await webHid.open({ label, muted, hookStatus: 'off' });
  }

  static sendReport(event, val) {
    switch (event) {
      case 'hookswitch':
        this.webHid.sendDeviceReport({ command: val ? 'offHook' : 'onHook' });
        break;
      case 'mute':
        this.webHid.sendDeviceReport({ command: val ? 'muteOn' : 'muteOff' });
        break;
      default:
        break;
    }
  }

  static async destroy() {
    await this.webHid.close();
  }
}
class HID {
  constructor(callback) {
    this.deviceUsage = {
      mute: { usageId: 0x080009, usageName: 'Mute' },
      offHook: { usageId: 0x080017, usageName: 'Off Hook' },
      ring: { usageId: 0x080018, usageName: 'Ring' },

      hookSwitch: { usageId: 0x0b0020, usageName: 'Hook Switch' },
      phoneMute: { usageId: 0x0b002f, usageName: 'Phone Mute' },
    };

    this.deviceCommand = {
      outputReport: {
        mute: { reportId: 0, usageOffset: -1 },
        offHook: { reportId: 0, usageOffset: -1 },
        ring: { reportId: 0, itemIndex: -1 },
      },
      inputReport: {
        hookSwitch: { reportId: 0, usageOffset: -1, isAbsolute: false },
        phoneMute: { reportId: 0, usageOffset: -1, isAbsolute: false },
      },
    };

    this.device = null;
    this.inputReportRetFunc = callback;
  }

  getHexByte(data) {
    let hex = Number(data).toString(16);
    while (hex.length < 2) hex = '0' + hex;
    return hex;
  }

  getHexByteStr(data) {
    let string = '';
    for (let i = 0; i < data.byteLength; ++i) {
      string += this.getHexByte(data.getUint8(i)) + ' ';
    }
    return string;
  }

  parseDeviceDescriptors() {
    try {
      this.outputEventGenerators = new Map();
      if (!(this.device && this.device.collections)) {
        return;
      }

      let telephoneCollection = this.device.collections.find(
        (collection) => collection.usagePage === UsagePage.TELEPHONY
      );
      if (
        !telephoneCollection ||
        Object.keys(telephoneCollection).length === 0
      ) {
        return;
      }

      if (telephoneCollection.inputReports) {
        if (!this.parseInputReports(telephoneCollection.inputReports)) {
          return false;
        } else {
        }
      }

      if (telephoneCollection.outputReports) {
        if (!this.parseOutputReports(telephoneCollection.outputReports)) {
          return false;
        } else {
          return true;
        }
      }
    } catch (e) {
      console.error(
        'parseDeviceDescriptors error:' + JSON.stringify(e, null, '    ')
      );
    }
  }

  parseInputReports(inputReports) {
    inputReports.forEach((report) => {
      if (!report.items.length || report.reportId === undefined) {
        return;
      }

      let usageOffset = 0;

      report.items.forEach((item) => {
        if (
          item.usages === undefined ||
          item.reportSize === undefined ||
          item.reportCount === undefined ||
          item.isAbsolute === undefined
        ) {
          return;
        }

        item.usages.forEach((usage, i) => {
          switch (usage) {
            case this.deviceUsage.hookSwitch.usageId:
              this.deviceCommand.inputReport['hookSwitch'] = {
                reportId: report.reportId,
                usageOffset: usageOffset + i * item.reportSize,
                isAbsolute: item.isAbsolute,
              };
              break;
            case this.deviceUsage.phoneMute.usageId:
              this.deviceCommand.inputReport['phoneMute'] = {
                reportId: report.reportId,
                usageOffset: usageOffset + i * item.reportSize,
                isAbsolute: item.isAbsolute,
              };
              break;
            default:
              break;
          }
        });

        usageOffset += item.reportCount * item.reportSize;
      });
    });

    if (
      this.deviceCommand.inputReport['phoneMute'].reportData === 0 ||
      this.deviceCommand.inputReport['hookSwitch'] === 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  parseOutputReports(outputReports) {
    outputReports.forEach((report) => {
      if (!report.items.length || report.reportId === undefined) {
        return;
      }

      let usageOffset = 0;
      let usageOffsetMap = new Map();

      report.items.forEach((item) => {
        if (
          item.usages === undefined ||
          item.reportSize === undefined ||
          item.reportCount === undefined
        ) {
          return;
        }

        item.usages.forEach((usage, i) => {
          switch (usage) {
            case this.deviceUsage.mute.usageId:
              this.deviceCommand.outputReport['mute'] = {
                reportId: report.reportId,
                usageOffset: usageOffset + i * item.reportSize,
              };
              usageOffsetMap.set(usage, usageOffset + i * item.reportSize);
              break;
            case this.deviceUsage.offHook.usageId:
              this.deviceCommand.outputReport['offHook'] = {
                reportId: report.reportId,
                usageOffset: usageOffset + i * item.reportSize,
              };
              usageOffsetMap.set(usage, usageOffset + i * item.reportSize);
              break;
            case this.deviceUsage.ring.usageId:
              this.deviceCommand.outputReport['ring'] = {
                reportId: report.reportId,
                usageOffset: usageOffset + i * item.reportSize,
              };
              usageOffsetMap.set(usage, usageOffset + i * item.reportSize);
              break;
            default:
              break;
          }
        });

        usageOffset += item.reportCount * item.reportSize;
      });

      let reportLength = usageOffset;
      for (let [usage, offset] of usageOffsetMap) {
        this.outputEventGenerators[usage] = (val) => {
          let reportData = new Uint8Array(reportLength / 8);

          if (offset >= 0 && val) {
            let byteIndex = Math.trunc(offset / 8);
            let bitPosition = offset % 8;
            reportData[byteIndex] = 1 << bitPosition;
          }

          return reportData;
        };
      }
    });

    let mute, ring, hook;
    for (let item in this.outputEventGenerators) {
      let newItem = this.getHexByte(item);
      newItem = '0x0' + newItem;
      if (this.deviceUsage.mute.usageId === Number(newItem)) {
        mute = this.outputEventGenerators[this.deviceUsage.mute.usageId];
      } else if (this.deviceUsage.offHook.usageId === Number(newItem)) {
        hook = this.outputEventGenerators[this.deviceUsage.offHook.usageId];
      } else if (this.deviceUsage.ring.usageId === Number(newItem)) {
        ring = this.outputEventGenerators[this.deviceUsage.ring.usageId];
      }
    }
    if (!mute && !ring && !hook) {
      return false;
    } else {
      return true;
    }
  }

  async open(data) {
    const pairedDevices = await navigator.hid.getDevices();
    if (pairedDevices.length) {
      this.device = pairedDevices.find((device) =>
        data.label.includes(device.productName)
      );
    }
    if (!this.device) {
      const devices = await navigator.hid.requestDevice({
        filters: [{ usagePage: UsagePage.TELEPHONY }],
      });
      if (devices.length) {
        this.device = devices[0];
      }
    }
    if (!this.device) {
      return false;
    }
    try {
      if (this.device.opened) {
        await this.device.close();
      }
      await this.device.open();
      if (!this.parseDeviceDescriptors()) {
        return false;
      }
      this.device.oninputreport = await this.handleInputReport.bind(this);
      this.resetState({ muted: data.muted, hookStatus: data.hookStatus });
      return true;
    } catch (e) {
      console.error('error content:' + e);
    }
  }

  async close() {
    try {
      this.resetState({ muted: false, hookStatus: 'on' });
      if (!this.device) {
        return;
      }
      if (this.device && this.device.opened) {
        await this.device.close();
      }
      this.device.oninputreport = null;
      this.device = null;
    } catch (e) {
      console.error(e);
    }
  }

  resetState({ muted, hookStatus }) {
    if (!this.device || !this.device.opened) {
      return;
    }
    this.device.hookStatus = hookStatus;
    this.device.muted = muted;
    this.device.ring = false;
    this.sendDeviceReport({
      command: hookStatus === 'off' ? 'offHook' : 'onHook',
    });
    this.sendDeviceReport({ command: muted ? 'muteOn' : 'muteOff' });
  }

  async sendDeviceReport(data) {
    if (!data || !data.command || !this.device || !this.device.opened) {
      return;
    }

    if (data.command === 'muteOn' || data.command === 'muteOff') {
      if (!this.outputEventGenerators[this.deviceUsage.mute.usageId]) {
        return;
      }
    } else if (data.command === 'onHook' || data.command === 'offHook') {
      if (!this.outputEventGenerators[this.deviceUsage.offHook.usageId]) {
        return;
      }
    } else if (data.command === 'onRing' || data.command === 'offRing') {
      if (!this.outputEventGenerators[this.deviceUsage.ring.usageId]) {
        return;
      }
    }

    let reportId = 0;
    let oldOffHook;
    let oldMuted;
    let oldRing;
    let newOffHook;
    let newMuted;
    let newRing;
    let offHookReport;
    let muteReport;
    let ringReport;
    let reportData = null;
    switch (data.command) {
      case 'muteOn':
      case 'muteOff':
        reportId = this.deviceCommand.outputReport['mute'].reportId;
        break;
      case 'onHook':
      case 'offHook':
        reportId = this.deviceCommand.outputReport['offHook'].reportId;
        break;
      case 'onRing':
      case 'offRing':
        reportId = this.deviceCommand.outputReport['ring'].reportId;
        break;
      default:
        return;
    }

    if (reportId == 0) {
      return;
    }

    oldMuted = this.device.muted;
    if (this.device.hookStatus === 'off') {
      oldOffHook = true;
    } else if (this.device.hookStatus === 'on') {
      oldOffHook = false;
    } else {
      return;
    }
    oldRing = this.device.ring;

    switch (data.command) {
      case 'muteOn':
        newMuted = true;
        break;
      case 'muteOff':
        newMuted = false;
        break;
      case 'onHook':
        newOffHook = false;
        break;
      case 'offHook':
        newOffHook = true;
        break;
      case 'onRing':
        newRing = true;
        break;
      case 'offRing':
        newRing = false;
        break;
      default:
        return;
    }

    if (this.outputEventGenerators[this.deviceUsage.mute.usageId]) {
      if (newMuted === undefined) {
        muteReport =
          this.outputEventGenerators[this.deviceUsage.mute.usageId](oldMuted);
      } else {
        muteReport =
          this.outputEventGenerators[this.deviceUsage.mute.usageId](newMuted);
      }
    }

    if (this.outputEventGenerators[this.deviceUsage.offHook.usageId]) {
      if (newOffHook === undefined) {
        offHookReport =
          this.outputEventGenerators[this.deviceUsage.offHook.usageId](
            oldOffHook
          );
      } else {
        offHookReport =
          this.outputEventGenerators[this.deviceUsage.offHook.usageId](
            newOffHook
          );
      }
    }

    if (this.outputEventGenerators[this.deviceUsage.ring.usageId]) {
      if (newRing === undefined) {
        ringReport =
          this.outputEventGenerators[this.deviceUsage.ring.usageId](oldRing);
      } else {
        ringReport =
          this.outputEventGenerators[this.deviceUsage.ring.usageId](newRing);
      }
    }

    if (reportId === this.deviceCommand.outputReport['mute'].reportId) {
      if (reportData === null) {
        reportData = new Uint8Array(muteReport);
      } else {
        for (const [i, data] of muteReport.entries()) {
          reportData[i] |= data;
        }
      }
    }

    if (reportId === this.deviceCommand.outputReport['offHook'].reportId) {
      if (reportData === null) {
        reportData = new Uint8Array(offHookReport);
      } else {
        for (const [i, data] of offHookReport.entries()) {
          reportData[i] |= data;
        }
      }
    }

    if (reportId === this.deviceCommand.outputReport['ring'].reportId) {
      if (reportData === null) {
        reportData = new Uint8Array(ringReport);
      } else {
        for (const [i, data] of ringReport.entries()) {
          reportData[i] |= data;
        }
      }
    }

    await this.device.sendReport(reportId, reportData);

    switch (data.command) {
      case 'muteOn':
        this.device.muted = true;
        break;
      case 'muteOff':
        this.device.muted = false;
        break;
      case 'onHook':
        this.device.hookStatus = 'on';
        break;
      case 'offHook':
        this.device.hookStatus = 'off';
        break;
      case 'onRing':
        this.device.ring = true;
        break;
      case 'offRing':
        this.device.ring = false;
        break;
      default:
        break;
    }
  }

  async sendReplyReport(inputReportId, curOffHook, curMuted) {
    let reportId = 0;
    if (
      this.deviceCommand.outputReport['offHook'].reportId ===
      this.deviceCommand.outputReport['mute'].reportId
    ) {
      reportId = this.deviceCommand.outputReport['offHook'].reportId;
    } else if (
      inputReportId === this.deviceCommand.inputReport['hookSwitch'].reportId
    ) {
      reportId = this.deviceCommand.outputReport['offHook'].reportId;
    } else if (
      inputReportId === this.deviceCommand.inputReport['phoneMute'].reportId
    ) {
      reportId = this.deviceCommand.outputReport['mute'].reportId;
    }
    if (!this.device || !this.device.opened) {
      return;
    }

    if (reportId === 0 || curOffHook === undefined || curMuted === undefined) {
      return;
    }

    let reportData;
    let muteReport;
    let offHookReport;
    let ringReport;

    if (
      this.deviceCommand.outputReport['offHook'].reportId ===
      this.deviceCommand.outputReport['mute'].reportId
    ) {
      muteReport =
        this.outputEventGenerators[this.deviceUsage.mute.usageId](curMuted);
      offHookReport =
        this.outputEventGenerators[this.deviceUsage.offHook.usageId](
          curOffHook
        );
      reportData = new Uint8Array(offHookReport);
      for (const [i, data] of muteReport.entries()) {
        reportData[i] |= data;
      }
    } else if (
      reportId === this.deviceCommand.outputReport['offHook'].reportId
    ) {
      offHookReport =
        this.outputEventGenerators[this.deviceUsage.offHook.usageId](
          curOffHook
        );
      reportData = new Uint8Array(offHookReport);
    } else if (reportId === this.deviceCommand.outputReport['mute'].reportId) {
      muteReport =
        this.outputEventGenerators[this.deviceUsage.mute.usageId](curMuted);
      reportData = new Uint8Array(muteReport);
    } else if (reportId === this.deviceCommand.outputReport['ring'].reportId) {
      ringReport =
        this.outputEventGenerators[this.deviceUsage.mute.usageId](curMuted);
      reportData = new Uint8Array(ringReport);
    }

    await this.device.sendReport(reportId, reportData);
  }

  handleInputReport(event) {
    let that = this;
    try {
      const { data, device, reportId } = event;
      if (reportId === 0) {
        return;
      } else {
      }

      let inputReport = that.deviceCommand.inputReport;
      if (
        reportId !== inputReport['hookSwitch'].reportId &&
        reportId !== inputReport['phoneMute'].reportId
      ) {
        return;
      }

      let hookStatusChange = false;
      let muteStatusChange = false;

      let reportData = new Uint8Array(data.buffer);
      let needReply = true;

      if (reportId === inputReport['hookSwitch'].reportId) {
        let item = inputReport['hookSwitch'];
        let byteIndex = Math.trunc(item.usageOffset / 8);
        let bitPosition = item.usageOffset % 8;
        let usageOn = (data.getUint8(byteIndex) & (0x01 << bitPosition)) !== 0;
        if (inputReport['hookSwitch'].isAbsolute) {
          if (that.device.hookStatus === 'on' && usageOn) {
            that.device.hookStatus = 'off';
            hookStatusChange = true;
          } else if (that.device.hookStatus === 'off' && !usageOn) {
            that.device.hookStatus = 'on';
            hookStatusChange = true;
          }
        } else if (usageOn) {
          that.device.hookStatus =
            that.device.hookStatus === 'off' ? 'on' : 'off';
          hookStatusChange = true;
        }
      }

      let oldMute = that.device.muted;
      if (reportId === inputReport['phoneMute'].reportId) {
        let item = inputReport['phoneMute'];
        let byteIndex = Math.trunc(item.usageOffset / 8);
        let bitPosition = item.usageOffset % 8;
        let usageOn = (data.getUint8(byteIndex) & (0x01 << bitPosition)) !== 0;
        if (inputReport['phoneMute'].isAbsolute) {
          if (that.device.muted != usageOn) {
            that.device.muted = usageOn;
            muteStatusChange = true;
          }
        } else if (usageOn) {
          that.device.muted = !that.device.muted;
          muteStatusChange = true;
        }
      }

      let inputReportData = {
        productName: device.productName,
        reportId: that.getHexByte(reportId),
        reportData: reportData,
      };

      if (hookStatusChange) {
        inputReportData.eventName = 'ondevicehookswitch';
        inputReportData.hookStatus = that.device.hookStatus;
      }

      if (muteStatusChange) {
        inputReportData.eventName = 'ondevicemuteswitch';
        inputReportData.isMute = that.device.muted;
      }

      that.inputReportRetFunc && that.inputReportRetFunc(inputReportData);

      if (needReply && (hookStatusChange || muteStatusChange)) {
        let newOffHook;
        if (this.device.hookStatus === 'off') {
          newOffHook = true;
        } else if (this.device.hookStatus === 'on') {
          newOffHook = false;
        } else {
          return;
        }
        that.sendReplyReport(reportId, newOffHook, that.device.muted);
      }
    } catch (e) {
      console.error(e);
    }
  }
}
