import IsHeadset from '../common/audio-headset';
import * as jsEvent from '../common/jsEvent';
import util from '../common/util';
import jsMediaEngineVariables from './JsMediaEngine_Variables';
import * as jsMediaEngine from '../lib/JsMediaEngine';

/*
ostype:typedef enum{
	A_UNKNOWN_CLI = 0,
    A_WIN_CLI,
    A_MAC_CLI,
	  A_PAD_CLI,
    A_MOBILE_CLI,
    A_CALL_IN_CLI,
    A_LINUX_CLI,
    A_WEB_CLI,

    // dylan.ye : vdi client flag
    A_VDI_RESERVE_BG = VDI_CLT_BASE,
    A_VDI_RESERVE_ED = VDI_CLT_BASE_END-1,
}CmmClientOSType;
*/
const A_WEB_CLI = 7;
//number of mic in capture device
const NUM_OF_DEVICE = 1;

function compareMaps(map1, map2) {
  var testVal;
  if (map1.size !== map2.size) {
    return false;
  }
  for (var [key, val] of map1) {
    testVal = map2.get(key);
    if (testVal !== val || (testVal === undefined && !map2.has(key))) {
      return false;
    }
  }
  return true;
}

function DeviceManager() {
  this.audioInputDeviceList = new Map();
  this.audioOutputDeviceList = new Map();
  this.videoInputDeviceList = new Map();
  this.micId = null;
  this.speakerId = null;
  this.cameraId = null;
  this.startTime = Date.now();
  this.userId = 0;
  this.videoWidth = 0;
  this.videoHeight = 0;
  this.isAudioBridge = false;
  this.isUpdatingDevice = false;
  this.denoiseSwitch = false;
}

DeviceManager.prototype.notifyDenoiseSetting = function () {
  if (!util.isSupportAudioDenoise() || !this.micId || this.isAudioBridge)
    return;
  let data = {
    command: 'audio_denoise_switch',
    switch: this.denoiseSwitch,
    isHeadSet: deviceManager.isHeadSet(),
  };
  jsMediaEngine.Notify_Audio_Encode_Thread(data);
};

DeviceManager.prototype.updateDeviceList = function (onlyUpdate) {
  this.isUpdatingDevice = true;
  let newAudioInputDevice = new Map();
  let newAudioOutputDevice = new Map();
  let newVideoInputDevice = new Map();
  navigator.mediaDevices.enumerateDevices().then((devices) => {
    devices.forEach((device) => {
      if (device.kind == 'audioinput') {
        newAudioInputDevice.set(
          device.deviceId,
          device.label.replace(/\([\w,\d]+:[\w,\d]+\)/i, '').trim()
        );
      } else if (device.kind == 'audiooutput') {
        newAudioOutputDevice.set(
          device.deviceId,
          device.label.replace(/\([\w,\d]+:[\w,\d]+\)/i, '').trim()
        );
      } else if (device.kind == 'videoinput') {
        newVideoInputDevice.set(
          device.deviceId,
          device.label.replace(/\([\w,\d]+:[\w,\d]+\)/i, '').trim()
        );
      }
    });

    this.isUpdatingDevice = false;
    if (
      !onlyUpdate &&
      !compareMaps(this.audioInputDeviceList, newAudioInputDevice) &&
      newAudioInputDevice.has(this.micId)
    ) {
      this.audioInputDeviceList = newAudioInputDevice;
      this.notifyDenoiseSetting();
      this.sendMicDevice(0, true);
    }
    if (
      !onlyUpdate &&
      !compareMaps(this.audioOutputDeviceList, newAudioOutputDevice) &&
      newAudioOutputDevice.has(this.speakerId)
    ) {
      this.audioOutputDeviceList = newAudioOutputDevice;
      this.sendSpeakerDevice(0, true);
    }
    if (
      !onlyUpdate &&
      !compareMaps(this.videoInputDeviceList, newVideoInputDevice) &&
      newVideoInputDevice.has(this.cameraId)
    ) {
      this.videoInputDeviceList = newVideoInputDevice;
      this.sendCameraInfo(0, true, -1, -1, 15, -1);
    }
    this.audioInputDeviceList = newAudioInputDevice;
    this.audioOutputDeviceList = newAudioOutputDevice;
    this.videoInputDeviceList = newVideoInputDevice;
    this.sendAllDevice();
  });
};

DeviceManager.prototype.changeDenoiseSwitch = function (flag) {
  this.denoiseSwitch = flag;
  this.notifyDenoiseSetting();
};

DeviceManager.prototype.setIsAudioBridge = function (flag) {
  this.isAudioBridge = flag;
};

DeviceManager.prototype.setUserId = function (id) {
  if (typeof id !== 'number' || Number.isNaN(id)) return;
  this.userId = id;
};

DeviceManager.prototype.setMicId = function (deviceId) {
  //deviceId is null if "same as default" is selected
  if (deviceId) {
    if (deviceId != this.micId) {
      this.micId = deviceId;
      if (!this.isUpdatingDevice) {
        this.notifyDenoiseSetting();
      }
      return true;
    }
  } else {
    this.micId = 'default';
  }
  return false;
};

DeviceManager.prototype.setSpeakerId = function (deviceId) {
  if (deviceId && deviceId != '') {
    this.speakerId = deviceId;
  } else {
    this.speakerId = 'default';
  }
};

DeviceManager.prototype.setVideoResolution = function (width, height) {
  this.videoWidth = width;
  this.videoHeight = height;
};

//video input no default device
DeviceManager.prototype.setCameraId = function (deviceId) {
  if (deviceId) {
    this.cameraId = deviceId;
  } else {
    this.cameraId = 'default';
  }
};

DeviceManager.prototype.getMicLabel = function () {
  return this.audioInputDeviceList.get(this.micId) || 'default';
};

//on safari, cant get 'default' device, but will set speakerId as 'default'
DeviceManager.prototype.getSpeakerLabel = function () {
  return this.audioOutputDeviceList.get(this.speakerId) || 'default';
};

//camera can't get 'default' device
DeviceManager.prototype.getCameraLabel = function () {
  return this.videoInputDeviceList.get(this.cameraId) || 'default';
};

DeviceManager.prototype.isHeadSet = function (isMic = true) {
  let deviceLabel = null;
  if (isMic) {
    deviceLabel = this.getMicLabel();
  } else {
    deviceLabel = this.getSpeakerLabel();
  }
  return IsHeadset(deviceLabel);
};

// 0: selected device
// -1 as index (<=> Default Communication Device)
// -2 Default endpoint device will be used

DeviceManager.prototype.getIndex = function (deviceId) {
  if (deviceId == 'default') return -1;
  else if (deviceId == 'communications') {
    if (util.isMac()) return 0;
    return -2;
  } else return 0;
};

DeviceManager.prototype.sendAllDevice = function () {
  if (this.isUpdatingDevice) return;
  this.audioInputDeviceList.forEach((deviceId, deviceLabel) => {
    this.sendDeviceInfo(
      -1,
      1,
      this.getIndex(deviceId),
      deviceId,
      deviceLabel,
      true
    );
  });
  this.audioOutputDeviceList.forEach((deviceId, deviceLabel) => {
    this.sendDeviceInfo(
      -1,
      0,
      this.getIndex(deviceId),
      deviceId,
      deviceLabel,
      true
    );
  });
};

DeviceManager.prototype.sendMicDevice = function (elapsed_time, success) {
  if (this.isUpdatingDevice) return;
  this.sendDeviceInfo(
    elapsed_time,
    1,
    this.getIndex(this.micId),
    this.micId,
    this.getMicLabel(),
    success,
    this.isHeadSet()
  );
};

DeviceManager.prototype.sendSpeakerDevice = function (elapsed_time, success) {
  if (this.isUpdatingDevice) return;
  this.sendDeviceInfo(
    elapsed_time,
    0,
    this.getIndex(this.speakerId),
    this.speakerId,
    this.getSpeakerLabel(),
    success,
    this.isHeadSet(false)
  );
};

//type: 0/1 is speaker/mic
DeviceManager.prototype.sendDeviceInfo = function (
  elapsed_time,
  type,
  index,
  deviceId,
  deviceLabel,
  success,
  isHeadSet
) {
  if (!this.userId) return;
  let log = null;
  let threadRunTime = Date.now() - this.startTime;
  if (elapsed_time == -1) {
    log =
      'WCL_AUDIOD-ALL,' +
      this.userId +
      ',' +
      type +
      ',' +
      index +
      ',' +
      deviceLabel +
      ',' +
      deviceId +
      ',' +
      success +
      ',' +
      elapsed_time +
      ',' +
      A_WEB_CLI +
      ',' +
      threadRunTime +
      ',' +
      NUM_OF_DEVICE;
  } else {
    log =
      'WCL_AUDIOD,' +
      this.userId +
      ',' +
      type +
      ',' +
      index +
      ',' +
      deviceLabel +
      ',' +
      deviceId +
      ',' +
      success +
      ',' +
      elapsed_time +
      ',' +
      A_WEB_CLI +
      ',' +
      threadRunTime +
      ',' +
      NUM_OF_DEVICE +
      ',' +
      (success ? 1 : 0) +
      ',' +
      isHeadSet;
  }
  jsMediaEngineVariables.sendMessageToRwg(jsEvent.MONITOR_LOG, {
    evt: 4167,
    seq: 1,
    body: {
      data: log,
    },
  });
};

DeviceManager.prototype.sendCameraInfo = function (
  elapsed_time,
  success,
  sessionState,
  colorFormat,
  fps,
  FECCState
) {
  if (!this.userId || this.isUpdatingDevice) return;
  let log = null;
  log =
    'WCL_CAMERA,' +
    this.userId +
    ',' +
    0 +
    ',' +
    this.getCameraLabel() +
    ',' +
    success +
    ',' +
    elapsed_time +
    ',' +
    sessionState +
    ',' +
    this.videoWidth +
    ',' +
    this.videoHeight +
    ',' +
    colorFormat +
    ',' +
    fps +
    ',' +
    FECCState;
  jsMediaEngineVariables.sendMessageToRwg(jsEvent.MONITOR_LOG, {
    evt: 4167,
    seq: 1,
    body: {
      data: log,
    },
  });
};

let deviceManager = new DeviceManager();

export default deviceManager;

//
// if (elapsed_time == -1)
//    {
//        device_stream << "AUDIOD-ALL,"<< m_self_id << "," << type<<","<<index<<","
//                         << des.p_name<<","<<des.p_unique_id << "," << success << ","
//                         << elapsed_time << "," << m_machine_info.os_type<<","<<tick_fix_t::now()<<","<<des.num_of_devices;
//    }
//    else
//    {
//        bool isHeadset = false;
//        if (index != -3) get_device_property(type,-3,SSB_MC_AUDIO_DEVICE_PROPERTY_HEADSET,&isHeadset,sizeof(bool));
//        device_stream << "AUDIOD," << m_self_id << "," << type<<","<<index<<","<< des.p_name<<","<<des.p_unique_id << ","
//                         << success << "," << elapsed_time << "," << m_machine_info.os_type<<","<<tick_fix_t::now()<<","
//                         <<des.num_of_devices << "," << result << "," << isHeadset;
//    }
