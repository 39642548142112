class JsMediaSDK_WaterMarkRGBA {
  _drawWatermarkWithShadow({ ctx, textPos, opacity, name }) {
    ctx.fillStyle = `rgba(0, 0, 0, ${opacity})`;
    ctx.fillText(name, textPos.x, textPos.y);
    ctx.fillStyle = `rgba(255, 255, 255, ${opacity})`;
    ctx.fillText(name, textPos.x + 1, textPos.y + 1);
  }

  _getTransformInfo({ canvas, position }) {
    let transformInfo;
    if (position === 1) {
      transformInfo = {
        x: canvas.width / 2,
        y: 0,
        rateRadio: 0,
        maxWidth: canvas.width,
      };
    } else if (position === 2) {
      transformInfo = {
        x: canvas.width / 2,
        y: canvas.height,
        rateRadio: 0,
        maxWidth: canvas.width,
      };
    } else if (position === 4) {
      transformInfo = {
        x: 0,
        y: canvas.height / 2,
        rateRadio: Math.PI / 2,
        maxWidth: canvas.height,
      };
    } else if (position === 8) {
      transformInfo = {
        x: canvas.width,
        y: canvas.height / 2,
        rateRadio: -Math.PI / 2,
        maxWidth: canvas.height,
      };
    } else {
      const rateRadio = (-21 * Math.PI) / 180;
      transformInfo = {
        x: canvas.width / 2,
        y: canvas.height / 2,
        rateRadio,
        maxWidth: Math.min(
          canvas.width / Math.cos(rateRadio),
          -canvas.height / Math.sin(rateRadio)
        ),
      };
    }
    if (transformInfo.maxWidth > 100) {
      transformInfo.maxWidth -= 50;
    }
    return transformInfo;
  }

  _calcTextPos({ position, ctx, name, textWidth }) {
    const padding = this._getPaddingWidth({ ctx, position, name });
    if (position === 1) {
      return {
        x: -textWidth.width / 2,
        y: padding,
      };
    } else if (position === 2) {
      return {
        x: -textWidth.width / 2,
        y: -padding,
      };
    } else if (position === 4) {
      return {
        x: -textWidth.width / 2,
        y: -padding,
      };
    } else if (position === 8) {
      return {
        x: -textWidth.width / 2,
        y: -padding,
      };
    }
    return {
      x: -textWidth.width / 2,
      y: ctx.measureText(name[0]).width / 2,
    };
  }

  /**
   * @description get the padding width when draw watermark
   */
  _getPaddingWidth({ ctx, position, name }) {
    if ([1, 2, 4, 8].includes(position)) {
      return 32;
    }
    // old flow keep 1 char size for padding
    return ctx.measureText(name[0]).width;
  }

  _setBaseLine({ ctx, position }) {
    if (position === 1) {
      ctx.textBaseline = 'top';
    } else if (position === 2) {
      ctx.textBaseline = 'bottom';
    } else if (position === 4) {
      ctx.textBaseline = 'bottom';
    } else if (position === 8) {
      ctx.textBaseline = 'bottom';
    } else {
      ctx.textBaseline = 'middle';
    }
  }

  /**
   * postion:
    DEFAULT: 0,
    TOP: 1,
    BOTTOM: 2,
    LEFT: 4,
    RIGHT: 8,
    MIDDLE: 16,
   */
  Get_WaterMarkRGBA({
    canvas,
    name,
    width,
    height,
    opacity = 0.15,
    position,
    convertToDataUrl,
  }) {
    if (!name || !width || !height) {
      return;
    }
    opacity = opacity || 0.15;
    var ratio = 1;
    width = width * ratio;
    height = height * ratio;

    canvas.width = width;
    canvas.height = height;
    let transformInfo = this._getTransformInfo({
      canvas,
      position,
    });
    var ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.translate(transformInfo.x, transformInfo.y);
    ctx.rotate(transformInfo.rateRadio);
    this._setBaseLine({ ctx, position });
    ctx.lineWidth = 1.0;
    ctx.imageSmoothingEnabled = true;
    let text_width;
    if (name.length == 1) {
      const fontSize = transformInfo.maxWidth / name.length;
      ctx.font = fontSize + "px  'Segoe UI'";
      text_width = ctx.measureText(name);
    } else {
      /** as UE required, minimum font size should be 16 px, if text_width too big, truncate to ... */
      let fontSize = 16;
      ctx.font = fontSize + "px  'Segoe UI'";
      text_width = ctx.measureText(name);
      while (
        text_width.width <
        transformInfo.maxWidth -
          2 * this._getPaddingWidth({ ctx, position, name })
      ) {
        fontSize = fontSize + 1;
        ctx.font = fontSize + "px  'Segoe UI'";
        text_width = ctx.measureText(name);
      }
      /** after calc the suitable fontSize, double check if the final text_width larger the maxWidth */
      if (
        text_width.width >
        transformInfo.maxWidth -
          2 * this._getPaddingWidth({ ctx, position, name })
      ) {
        if (fontSize > 16) {
          /** calc too long */
          fontSize = fontSize - 1;
          ctx.font = fontSize + "px  'Segoe UI'";
          text_width = ctx.measureText(name);
        } else {
          /** name too long, truncate to ... */
          const originalName = name;
          while (
            name.length > 5 &&
            text_width.width >
              transformInfo.maxWidth -
                2 * this._getPaddingWidth({ ctx, position, name: name + '...' })
          ) {
            /** remove last char of name */
            name = name.slice(0, name.length - 1);
            text_width = ctx.measureText(name + '...');
          }
          if (originalName !== name) name = name + '...';
        }
      }
    }
    const textPos = this._calcTextPos({
      position,
      ctx,
      name,
      textWidth: text_width,
    });
    this._drawWatermarkWithShadow({
      ctx,
      name,
      opacity,
      textPos,
    });
    var water_mark;
    if (convertToDataUrl) {
      water_mark = canvas.toDataURL();
    } else {
      var image_data = ctx.getImageData(
        0,
        0,
        ctx.canvas.width,
        ctx.canvas.height
      );
      water_mark = new Uint8Array(image_data.data.buffer);
    }
    // this is for firefox galleryview, seems firefox offscreencanvas will not reset rotate and translate info when get a new context('2d')
    ctx.rotate(-transformInfo.rateRadio);
    ctx.translate(-transformInfo.x, -transformInfo.y);
    return water_mark;
  }

  // 是否开启repeat，看宽高临界值306宽乘 202高
  Get_Repeated_WaterMarkRGBA({
    canvas,
    name,
    width,
    height,
    opacity = 0.15,
    position,
    convertToDataUrl,
  }) {
    if (!name || !width || !height) return;
    opacity = opacity || 0.15;
    const ratio = 1;
    width = width * ratio;
    height = height * ratio;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.translate(width / 2, height / 2);
    ctx.rotate((-21 * Math.PI) / 180);
    ctx.imageSmoothingEnabled = true;
    const fontSize = 32;
    ctx.font = `${fontSize}px 'Segoe UI'`;
    ctx.textBaseline = 'top';
    const text_width = ctx.measureText(name);
    const paddingStep = 0.37 * text_width.width;
    let rawIndex = 0;
    let heightFlag = -height;
    do {
      let widthFlag = rawIndex % 2 === 0 ? paddingStep - width : -width;
      do {
        ctx.fillStyle = `rgba(0, 0, 0, ${opacity})`;
        ctx.fillText(name, widthFlag, heightFlag);
        ctx.fillStyle = `rgba(255, 255, 255, ${opacity})`;
        ctx.fillText(name, widthFlag + 1, heightFlag + 1);
        widthFlag += text_width.width + paddingStep;
      } while (widthFlag < width);
      heightFlag += fontSize + paddingStep;
      rawIndex++;
    } while (heightFlag < height);
    let water_mark;
    if (convertToDataUrl) {
      water_mark = canvas.toDataURL();
    } else {
      const image_data = ctx.getImageData(
        0,
        0,
        ctx.canvas.width,
        ctx.canvas.height
      );
      water_mark = new Uint8Array(image_data.data.buffer);
    }
    // this is for firefox galleryview, seems firefox offscreencanvas will not reset rotate and translate info when get a new context('2d')
    ctx.rotate((21 * Math.PI) / 180);
    ctx.translate(-width / 2, -height / 2);
    return water_mark;
  }
}

export default JsMediaSDK_WaterMarkRGBA;
