let wasmfeature = function () {};

wasmfeature.prototype = {
  threads: async () =>
    (async (e) => {
      if (!WebAssembly.validate(e)) return !1;
      try {
        return (
          new MessageChannel().port1.postMessage(new SharedArrayBuffer(1)), !0
        );
      } catch (e) {
        return !1;
      }
    })(
      // This typed array is a WebAssembly program containing threaded
      // instructions.
      new Uint8Array([
        0, 97, 115, 109, 1, 0, 0, 0, 1, 4, 1, 96, 0, 0, 3, 2, 1, 0, 5, 4, 1, 3,
        1, 1, 10, 11, 1, 9, 0, 65, 0, 254, 16, 2, 0, 26, 11,
      ])
    ),
  simd: async () =>
    // This typed array passed in to WebAssembly.validate is WebAssembly binary
    // code. In this case it is a small program that contains SIMD
    // instructions.
    // console.error('false false');
    // return false;
    WebAssembly.validate(
      new Uint8Array([
        0, 97, 115, 109, 1, 0, 0, 0, 1, 4, 1, 96, 0, 0, 3, 2, 1, 0, 10, 9, 1, 7,
        0, 65, 0, 253, 15, 26, 11,
      ])
    ),
};

export default new wasmfeature();
