/**
 * you can add your custom exception/error in this file.
 */

/**
 * the computer camera is occupied, then no mediaStream obtained
 * @param message
 * @constructor
 */
export function CameraOccupiedError(message) {
  this.name = 'CameraOccupiedError';
  this.message = message;
  this.stack = new Error().stack;
}

CameraOccupiedError.prototype = new Error();
