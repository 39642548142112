import throttle from 'lodash/throttle';
import Log from '../common/log';
import pubsub from '../common/pubSub';
import * as jsEvent from '../common/jsEvent';
import isNumber from 'lodash/isNumber';
import utf8 from 'utf8';
import util from '../common/util';
import { OS_ENUM } from '../common/enums/CommonEnums';

const log = Log('sdk.remoteControl');

const PDU_TYPE_ENUM = {
  UNKNOWN: 0,
  KEEP_ALIVE: 0,
  MOUSE: 83,
  SEND_POSITION_PARAMS: 82,
  WHEEL: 84,
  KEYBOARD: 85,
  SEND_CHARS: 86,
  SYNC_CLIPBOARD: 87,
  COPY_TEXT_FROM_RWG: 88,
};

/**
 *
 * @param {Object}  [params.dom]
 * @param {String}  [params.socketURL]
 * @param {String}  [params.meetingID]
 * @param {String}  [params.condID]
 * @param {Boolean} [params.isEnablePaste=true]
 * @constructor
 */
function RemoteControl(params) {
  let self = this;

  this.dom = params.dom;
  this.socketURL = params.socketURL;
  this.meetingID = params.meetingID;
  this.condID = params.condID;
  this.blobSocket = null;
  let _keyMouseEventHandlerBindThis = this._keyMouseEventHandler.bind(this);
  this.keyMouseEventHandler = function (e) {
    self.keyMouseFilters(e).then((e) => {
      _keyMouseEventHandlerBindThis(e);
    });
  };
  this.keyMouseEventHandlerThrottle = throttle(function (e) {
    self.keyMouseEventHandler(e);
  }, 1000 / 24);
  this.pasteOnDocument = this._pasteOnDocument.bind(this);
  this.copyOnDocument = this._copyOnDocument.bind(this);
  this.cutOnDocument = this._cutOnDocument.bind(this);
  this.focus = this._focus.bind(this);

  /**
   * use this token to unsubscribe SHARING_PARAM_INFO_FROM_SOCKET event
   */
  this.PUBSUB_SHARING_PARAM_INFO_TOKEN = null;
  this.preventDefault = this._preventDefault.bind(this);
  this.browserBlurEventHandler = this._browserBlurEventHandler.bind(this);

  this.positionMap = {
    canvas: {
      offsetX: 0,
      offsetY: 0,
    },
    src: {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      scaleWidth: 0,
      scaleHeight: 0,
    },
    dst: {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    },
  };
  this.blockedKeyboardEventOfPaste = [];
  this.keydownupMergeRemoveMap = {};
  this.keyMouseEventHandlerFilter_every_keyup_must_have_keydown_before_Map = {};
  this.PASTE_MAX_BYTE_LENGTH = 64 * 1000; // 64kb
  this.currentMousePositionProps = {
    clientX: 0,
    clientY: 0,
    x2video: 0,
    y2video: 0,
  };
  /**
   * If isControllerNow is TRUE, sdk will send control directives to remote computer, else not.
   * @type {boolean}
   */
  this.isControllerNow = true;
  this.remoteOS = OS_ENUM.UNKNOWN;
  this.localOS = this.getLocalOS(); // not reliable
  this._blockedCopyKeyboardList = [];

  this.maxSleep = 500;
}

RemoteControl.prototype = {
  start() {
    return new Promise(async (resolve, reject) => {
      try {
        log('remote control start');
        this.modifyDom();
        this.bindEvent();
        this.subscribeSharingWidthOrHeightChange();
        // use blur event to clear keydown, rather than setInterval
        // this.checkAndClearKeydownupMergeRemoveMap();
        await this.connectSocket();
        this.sendPostionPDU();
        this.socketBlobMessageHandler();
        resolve(true);
      } catch (ex) {
        reject(ex);
      }
    });
  },
  destroy() {
    return new Promise((resolve, reject) => {
      try {
        log('destroy remote control');
        this.unbindEvent();
        this.uncheckAndClearKeydownupMergeRemoveMap();
        this.destroySocket();
        pubsub.unsubscribe(this.PUBSUB_SHARING_PARAM_INFO_TOKEN);
        resolve(true);
      } catch (ex) {
        log(ex);
        resolve(false);
      }
    });
  },
  modifyDom() {
    try {
      this.dom.setAttribute('tabindex', '0');
      this.dom.focus();
    } catch (ex) {}
  },
  _focus() {
    this.dom.focus();
  },
  getLocalOS() {
    let platform = global.navigator.platform;
    if (/win/i.test(platform)) {
      return OS_ENUM.WIN;
    } else if (/mac/i.test(platform)) {
      return OS_ENUM.MAC;
    } else {
      return OS_ENUM.UNKNOWN;
    }
  },
  setIsControlerNow(isControllerNow) {
    this.isControllerNow = isControllerNow;
  },
  blobSocketCheckAndSend(buffer) {
    if (this.isControllerNow) {
      this.blobSocket.send(buffer);
    }
  },
  isKeyboardEvent(e) {
    if (!e) return false;
    return e.type && ['keydown', 'keyup'].indexOf(e.type.toLowerCase()) !== -1;
  },
  /**
   * @param e {Event}
   * @param key {String}
   */
  isThisKeyIgnoreCase(e, key) {
    return e.key && e.key.toLowerCase() == key.toLowerCase();
  },
  async _copyOnDocument(e) {
    if (!this.isFocusNow()) return;

    let list = this._blockedCopyKeyboardList;
    let lasteOne = list[list.length - 1];

    // avoid the influence of document.execommand("copy")
    if (lasteOne == 'done') {
      return;
    } else {
      this._blockedCopyKeyboardList = [];
      this._blockedCopyKeyboardList.push('done');
    }

    log('copyOnDocument', e);
    this.processMonitorCtrlMetaWithKey('c');
  },
  async _cutOnDocument(e) {
    if (!this.isFocusNow()) return;
    log('_cutOnDocument', e);
    this.processMonitorCtrlMetaWithKey('x');
  },
  processMonitorCtrlMetaWithKey(k) {
    let inputKeyTypeMap = {
      keydown: 0,
      keyup: 1,
      keychar: 2,
    };
    let isRemoteMACOS = this.remoteOS === OS_ENUM.MAC;
    let commonObj = {
      t: 'keydown',
      event_type: 1,
      repeat: false,
      alt: false,
      shift: false,
      capslock: false,
      numlock: true,
      pduType: PDU_TYPE_ENUM.KEYBOARD,
      ctrl: !isRemoteMACOS,
      super: isRemoteMACOS,
    };
    let ctrlObj = Object.assign({}, commonObj, {
      charCode: 0,
      keyCode: isRemoteMACOS ? 91 : 17,
      key: isRemoteMACOS ? 'Meta' : 'Control',
    });
    let cObj = Object.assign({}, commonObj, {
      charCode: k.charCodeAt(0),
      keyCode: k.toUpperCase().charCodeAt(0),
      key: k,
    });

    let obj1 = Object.assign({}, ctrlObj, {
      input_event_type: inputKeyTypeMap.keydown,
    });
    let obj2 = Object.assign({}, ctrlObj, {
      input_event_type: inputKeyTypeMap.keychar,
    });
    let obj3 = Object.assign({}, cObj, {
      input_event_type: inputKeyTypeMap.keydown,
    });
    let obj4 = Object.assign({}, cObj, {
      input_event_type: inputKeyTypeMap.keychar,
    });

    let obj5 = Object.assign({}, ctrlObj, {
      t: 'keyup',
      ctrl: false,
      super: false,
      input_event_type: inputKeyTypeMap.keyup,
    });
    let obj6 = Object.assign({}, cObj, {
      t: 'keyup',
      ctrl: false,
      super: isRemoteMACOS,
      input_event_type: inputKeyTypeMap.keyup,
    });
    var orders;
    if (isRemoteMACOS) {
      orders = [obj1, obj3, obj6, obj5];
    } else {
      orders = [obj1, obj3, obj5, obj6];
    }

    orders.forEach((item) => {
      this.processEvent(item);
    });
  },
  isFocusNow() {
    return this.dom === document.activeElement;
  },
  async _pasteOnDocument(e) {
    if (this.isFocusNow()) {
      let text = (e.clipboardData || window.clipboardData).getData('text');

      log('_pasteOnDocument', text);
      let byteArr = [];

      for (let ii = 0; ii < text.length; ii++) {
        let char = text[ii];
        let encoded = utf8.encode(char);
        for (let i = 0; i < encoded.length; i++) {
          byteArr.push(encoded.charCodeAt(i));
        }
      }

      let buffer = new ArrayBuffer(8 + byteArr.length);
      let type = new Uint8Array(buffer);
      let srcView = new DataView(buffer, 4, 4);
      let data = new Uint8Array(buffer, 8);

      type[0] = PDU_TYPE_ENUM.SYNC_CLIPBOARD;
      srcView.setUint32(0, byteArr.length, true);
      for (let i = 0; i < byteArr.length; i++) {
        data[i] = byteArr[i];
      }

      if (byteArr.length > this.PASTE_MAX_BYTE_LENGTH) {
        this.triggerPasteTextLengthOverflow();
        this.dropAllEventFromBlockedKeyboardEventOfPaste();
      } else {
        this.blobSocketCheckAndSend(buffer);
        this.processMonitorCtrlMetaWithKey('v');
      }
    }
  },
  onReturnCopiedText(fn) {
    !this._onReturnCopiedText_list && (this._onReturnCopiedText_list = []);
    this._onReturnCopiedText_list.push(fn);
  },
  triggerReturnCopiedText(data) {
    if (this._onReturnCopiedText_list) {
      this._onReturnCopiedText_list.forEach((fn) => fn(data));
    }
  },
  onPasteTextLengthOverflow(fn) {
    !this._onPasteTextLengthOverflow_list &&
      (this._onPasteTextLengthOverflow_list = []);
    this._onPasteTextLengthOverflow_list.push(fn);
  },
  triggerPasteTextLengthOverflow() {
    if (this._onPasteTextLengthOverflow_list) {
      this._onPasteTextLengthOverflow_list.forEach((fn) => fn());
    }
  },
  dropAllEventFromBlockedKeyboardEventOfPaste() {
    this.blockedKeyboardEventOfPaste = [];
  },
  createParamsFromKeyboardEvent(e) {
    return {
      key: e.key,
      code: e.code,
      shiftKey: e.shiftKey,
      altKey: e.altKey,
      repeat: e.repeat,
      charCode: e.charCode,
      keyCode: e.keyCode,
      which: e.which,
      ctrlKey: e.ctrlKey,
      metaKey: e.metaKey,
    };
  },
  sendAllEventFromBlockedKeyboardEventOfPaste() {
    log(
      'blockedKeyboardEventOfPaste.length',
      this.blockedKeyboardEventOfPaste.length
    );
    while (this.blockedKeyboardEventOfPaste.length > 0) {
      let e = this.blockedKeyboardEventOfPaste.shift();
      this._keyMouseEventHandler(e);
    }
  },
  /**
   * all preventDefault
   * many keys down/up must be preventDefault
   * such as F1, it means open help info
   * these keys could influence the behaviour of webClient
   * so they must be preventDefault
   * except white list
   * @param e
   * @private
   */
  _preventDefault(e) {
    let keyTypes = ['keydown', 'keyup'];

    if (keyTypes.indexOf(e.type) !== -1) {
      // for some key events, ctrl + ?  should not be preventDefault
      // because ctrl + v could be a paste event
      let ctrlWithList = ['v', 'c', 'x'];
      for (let i = 0; i < ctrlWithList.length; i++) {
        if (
          e.ctrlKey ^ e.metaKey &&
          this.isThisKeyIgnoreCase(e, ctrlWithList[i])
        ) {
          return;
        }
      }
    }
    e.preventDefault();
  },
  /**
   * trigger on the destination sharing video's width or height is changed,
   * so the positionMap should be changed
   */
  subscribeSharingWidthOrHeightChange() {
    let that = this;
    this.PUBSUB_SHARING_PARAM_INFO_TOKEN = pubsub.on(
      jsEvent.SHARING_PARAM_INFO_FROM_SOCKET,
      (msg, data) => {
        log('subscribeSharingWidthOrHeightChange', data.body);
        that.positionMap.dst.w = data.body.logicWidth;
        that.positionMap.dst.h = data.body.logicHeight;

        that.positionMap.src.w = data.body.logicWidth;
        that.positionMap.src.h = data.body.logicHeight;

        that.sendPostionPDU();
      }
    );
  },
  /**
   * Set the width and height of the other share video
   * @param width
   * @param height
   */
  setDstWidthAndHeight(width, height) {
    if (!width || !height) {
      throw new Error(
        'the value of destination sharing video width/height are not correct'
      );
    }

    this.positionMap.dst.w = width;
    this.positionMap.dst.h = height;
  },
  /**
   * set the width and height of video in web page
   */
  setSrcWidthAndHeight(width, height) {
    if (!width || !height) {
      throw new Error(
        'the value of source sharing video width/height are not correct'
      );
    }
    this.positionMap.src.w = width;
    this.positionMap.src.h = height;
  },
  setSrcWidthAndHeightAndSendPDU(width, height) {
    if (!width || !height) {
      throw new Error(
        'the value of source sharing video width/height are not correct'
      );
    }
    if (this.positionMap.src.w !== width && this.positionMap.src.h !== height) {
      this.setSrcWidthAndHeight(width, height);
      this.sendPostionPDU();
    }
  },
  setSrcOffsetXYAndSendPDU(offsetX, offsetY) {
    offsetX = Math.floor(offsetX);
    offsetY = Math.floor(offsetY);
    if (
      this.positionMap.canvas.offsetX !== offsetX ||
      this.positionMap.canvas.offsetY !== offsetY
    ) {
      this.setSrcOffsetXY(offsetX, offsetY);
      this.sendPostionPDU();
    }
  },
  /**
   * set the width and height of video in web page
   */
  setSrcScaleWidthAndHeight(scaleWidth, scaleHeight) {
    if (!scaleWidth || !scaleHeight) {
      throw new Error(
        'the value of source sharing video scaleWidth/scaleHeight are not correct'
      );
    }
    this.positionMap.src.scaleWidth = scaleWidth;
    this.positionMap.src.scaleHeight = scaleHeight;
  },
  setSrcOffsetXY(offsetX = 0, offsetY = 0) {
    let x = Math.floor(offsetX);
    let y = Math.floor(offsetY);
    if (!(isNumber(x) || isNumber(y))) {
      throw new Error('the value of source offset x or y is not correct');
    }
    this.positionMap.canvas.offsetX = x;
    this.positionMap.canvas.offsetY = y;
  },
  sendPostionPDU() {
    let buffer = new ArrayBuffer(4 * 9);
    let type = new Uint8Array(buffer);
    let srcView = new DataView(buffer, 4);
    let dstView = new DataView(buffer, 4 * 5);

    type[0] = PDU_TYPE_ENUM.SEND_POSITION_PARAMS;
    srcView.setInt32(0 * 4, this.positionMap.src.x, true);
    srcView.setInt32(1 * 4, this.positionMap.src.y, true);
    srcView.setInt32(2 * 4, this.positionMap.src.w, true);
    srcView.setInt32(3 * 4, this.positionMap.src.h, true);

    dstView.setInt32(0 * 4, this.positionMap.dst.x, true);
    dstView.setInt32(1 * 4, this.positionMap.dst.y, true);
    dstView.setInt32(2 * 4, this.positionMap.dst.w, true);
    dstView.setInt32(3 * 4, this.positionMap.dst.h, true);

    log('send position pdu');
    try {
      this.blobSocket.send(buffer);
    } catch (ex) {}
  },
  unbindEvent() {
    let self = this;
    let dom = this.dom;

    dom.removeEventListener('click', self.focus);

    // Don't try `keypress`.
    dom.removeEventListener('keydown', self.keyMouseEventHandler);
    dom.removeEventListener('keyup', self.keyMouseEventHandler);
    document.removeEventListener('paste', self.pasteOnDocument);
    document.removeEventListener('copy', self.copyOnDocument);
    document.removeEventListener('cut', self.cutOnDocument);

    dom.removeEventListener('contextmenu', self.preventDefault);
    dom.removeEventListener('wheel', self.preventDefault);
    dom.removeEventListener('dblclick', self.preventDefault);
    dom.removeEventListener('keydown', self.preventDefault);
    dom.removeEventListener('keyup', self.preventDefault);

    dom.removeEventListener('dblclick', self.keyMouseEventHandler);
    dom.removeEventListener('mousedown', self.keyMouseEventHandler);
    dom.removeEventListener('mouseup', self.keyMouseEventHandler);
    dom.removeEventListener('mousemove', self.keyMouseEventHandlerThrottle);
    dom.removeEventListener('wheel', self.keyMouseEventHandler);

    window.removeEventListener('blur', self.browserBlurEventHandler);
  },
  bindEvent() {
    let self = this;
    let dom = this.dom;
    log('dom', dom);

    dom.addEventListener('click', self.focus);

    // Don't try `keypress`.
    dom.addEventListener('keydown', self.keyMouseEventHandler);
    dom.addEventListener('keyup', self.keyMouseEventHandler);
    document.addEventListener('paste', self.pasteOnDocument);
    document.addEventListener('copy', self.copyOnDocument);
    document.addEventListener('cut', self.cutOnDocument);

    dom.addEventListener('contextmenu', self.preventDefault);
    dom.addEventListener('wheel', self.preventDefault);
    dom.addEventListener('dblclick', self.preventDefault);
    dom.addEventListener('keydown', self.preventDefault);
    dom.addEventListener('keyup', self.preventDefault);

    dom.addEventListener('dblclick', self.keyMouseEventHandler);
    dom.addEventListener('mousedown', self.keyMouseEventHandler);
    dom.addEventListener('mouseup', self.keyMouseEventHandler);
    dom.addEventListener('mousemove', self.keyMouseEventHandlerThrottle);
    dom.addEventListener('wheel', self.keyMouseEventHandler);

    window.addEventListener('blur', self.browserBlurEventHandler);
  },
  recordCurrentMousePosition(e) {
    try {
      let map = this.positionMap;
      let x = e.clientX;
      let y = e.clientY;

      Object.assign(this.currentMousePositionProps, {
        clientX: x,
        clientY: y,
        x2video: x - map.canvas.offsetX,
        y2video: y - map.canvas.offsetY,
      });
    } catch (ex) {}
  },
  resolvePosition(e) {
    let x = e.clientX;
    let y = e.clientY;
    let map = this.positionMap;

    x = x - map.canvas.offsetX;
    y = y - map.canvas.offsetY;

    x = x * (map.dst.w / map.src.scaleWidth);
    y = y * (map.dst.h / map.src.scaleHeight);

    x = Math.floor(x);
    y = Math.floor(y);

    return {
      x,
      y,
    };
  },
  /**
   *
   * @param {Number} params.pduType
   * @param {Number} params.event_type  - (0 : mouse event, 1:key event)
   * @param {Number} params.input_event_type
   * @param {Number} params.x     - [optional, default=0]
   * @param {Number} params.y     - [optional, default=0]
   * @param {Number} params.dx          - [optional, default=0]
   * @param {Number} params.dy          - [optional, default=0]
   * @param {Boolean} params.shift      - [optional, default=false] modifier
   * @param {Boolean} params.capslock   - [optional, default=false] modifier
   * @param {Boolean} params.ctrl       - [optional, default=false] modifier
   * @param {Boolean} params.alt        - [optional, default=false] modifier
   * @param {Boolean} params.numlock    - [optional, default=false] modifier
   * @param {Boolean} params.super      - [optional, default=false] modifier
   * @param {Number} params.repeat      - [optional, default=0]
   * @param {Number} params.key         - [optional, no default value]
   * @param {Number} params.charCode    - [optional, no default value]
   * @param {Number} params.keyCode     - [optional, no default value]
   * @returns {Promise<void>}
   */
  generateBuffer(params) {
    if (params.key !== undefined && !/^[\x00-\x7F]*$/.test(params.key)) {
      throw new Error('params.key must only contain ascii character');
    }
    params = Object.assign(
      {
        v: 0,
        x: 0,
        y: 0,
        dx: 0,
        dy: 0,
        repeat: 0,
      },
      params
    );

    let byteLen = 4 * 2;

    if (params.pduType === PDU_TYPE_ENUM.WHEEL) {
      byteLen += 4;
    }

    let buffer = new ArrayBuffer(byteLen);
    let uint8 = new Uint8Array(buffer);
    let uint16View = new DataView(buffer, 4);
    let int16View = new DataView(buffer, 4);

    uint8[0] = params.pduType;
    uint8[1] = params.input_event_type & 63; // 63 => 0b00111111

    if (params.pduType === PDU_TYPE_ENUM.MOUSE) {
      uint16View.setUint16(0, params.x, true);
      uint16View.setUint16(2, params.y, true);
    } else if (params.pduType === PDU_TYPE_ENUM.WHEEL) {
      int16View.setInt16(0, params.x, true);
      int16View.setInt16(2, params.y, true);

      int16View.setInt16(4, params.dx, true);
      int16View.setInt16(6, params.dy, true);
    } else if (params.pduType === PDU_TYPE_ENUM.KEYBOARD) {
      let keyCodeView = new DataView(buffer, 4);
      let keyValueView = new DataView(buffer, 6);
      let modifierBuffer = new Uint8Array(buffer, 2);

      keyCodeView.setUint16(0, params.keyCode, true);
      keyValueView.setUint16(0, params.charCode, true);

      let modifierMap = {
        shift: 1,
        capslock: 2,
        ctrl: 4,
        alt: 8,
        numlock: 16,
        super: 32,
      };
      let modifier = 0;
      Object.keys(modifierMap).forEach((k) => {
        if (params[k] === true) {
          modifier += modifierMap[k];
        }
      });
      modifierBuffer[0] = modifier;

      if (params.repeat) {
        uint8[1] = uint8[1] | 32; // 32 => 0b00100000
      }
    }

    return buffer;
  },
  processEvent(params) {
    if (this.blobSocket) {
      this.blobSocketCheckAndSend(this.generateBuffer(params));
    }
  },
  debug(params) {
    let list = [];
    let obj = {};
    if (params.pduType === PDU_TYPE_ENUM.KEYBOARD) {
      list = ['t', 'keyCode', 'charCode', 'key'];
    } else {
      list = ['t', 'x', 'y', 'dx', 'dy', 'input_event_type'];
    }
    list.forEach((k) => {
      obj[k] = params[k];
    });

    log('debug', JSON.stringify(obj));
  },
  /**
   * ctrl+v cannot be sent right now when triggered
   * because the correct step is :
   * 1. sync remote computer's clipboard with paste data
   * 2. then trigger ctrl + v event to remote computer
   * 3. paste event is done.
   * @param e
   * @returns {Promise<Event>}
   */
  blockedPasteKeyboard(e) {
    let that = this;
    return new Promise((resolve, reject) => {
      if (that.isKeyboardEvent(e) && that.isPasteEvent(e)) {
        that.blockedKeyboardEventOfPaste.push(e);
      } else {
        resolve(e);
      }
    });
  },
  isPasteEvent(e) {
    let isV = this.isThisKeyIgnoreCase(e, 'v');
    if (e.ctrlKey ^ e.metaKey && isV) {
      return true;
    }
    return false;
  },
  isCopyEvent(e) {
    let isC = this.isThisKeyIgnoreCase(e, 'c');
    if (e.ctrlKey ^ e.metaKey && isC) {
      return true;
    } else {
      return false;
    }
  },
  isCutEvent(e) {
    let isX = this.isThisKeyIgnoreCase(e, 'x');
    if (e.ctrlKey ^ e.metaKey && isX) {
      return true;
    } else {
      return false;
    }
  },
  returnNewEventByTransformCtrlKeyOrCommandKey(e) {
    let params = this.createParamsFromKeyboardEvent(e);
    switch (this.remoteOS) {
      case OS_ENUM.WIN:
        Object.assign(params, {
          ctrlKey: true,
          metaKey: false,
        });
        break;
      case OS_ENUM.MAC:
        Object.assign(params, {
          ctrlKey: false,
          metaKey: true,
        });
        break;
      case OS_ENUM.UNKNOWN:
      default:
        break;
    }

    return new e.constructor(e.type, params);
  },
  transformCtrlCAndCommandC(e) {
    let that = this;
    return new Promise((resolve, reject) => {
      if (
        this.isKeyboardEvent(e) &&
        (e.ctrlKey || e.metaKey) &&
        that.isThisKeyIgnoreCase(e, 'c')
      ) {
        let params = that.createParamsFromKeyboardEvent(e);

        let newEvent = that.returnNewEventByTransformCtrlKeyOrCommandKey(e);

        resolve(newEvent);
      } else {
        resolve(e);
      }
    });
  },
  /**
   * this method works together with {@link checkAndClearKeydownupMergeRemoveMap}
   * when key is down, it will be added or updated to the MapTable
   * when key is up , it will be removed from the MapTable
   * once detect any key exists more than {@link checkAndClearKeydownupMergeRemoveMap maxSleep} milliseconds,
   * the key will be removed from the MapTable and automatically send a keyup event of that key to websocket
   *
   * Why need this ?
   * For example : you input `alt + tab` on windows
   * the remote control program can only receive a keydown event of `alt` because the combination of `alt + tab` will
   * make Windows System switch to another windows program, this will make webClient miss the keyup event of `alt`
   * but the remote controlled client is waiting for a keyup event of `alt`
   * so later when user switch back to webClient and input `s`, it won't be purely `s`  but `alt + s` on the remote client
   * this can be unpredictable and confusing, so we need following processing
   * @param e
   * @returns {Promise<Event>}
   */
  keyMouseEventHandlerFilter_MatainKeyDownUpMergeMap(e) {
    return new Promise((resolve, reject) => {
      try {
        if (this.isKeyboardEvent(e) && e.key) {
          if (e.type === 'keydown' && !e.repeat) {
            this.keydownupMergeRemoveMap[e.key] = {
              t: +new Date(),
              e,
            };
          }
          /**
           * why need this ?
           * For example :
           * when you press `shift` and hold, then keydown event trigger one by one,
           * but later you press another key, the the keydown event of `shift` disappears
           * after a while, webclient will automatically trigger a keyup event of `shift`,
           * this is confusing, casue you are still holding the `shift`.
           */
          if (e.type === 'keydown' && e.repeat) {
            delete this.keydownupMergeRemoveMap[e.key];
          }
          if (e.type === 'keyup') {
            delete this.keydownupMergeRemoveMap[e.key];
          }
        }
      } catch (ex) {
        log.error(ex);
      }
      resolve(e);
    });
  },
  _browserBlurEventHandler() {
    this._checkAndClearKeydownupMergeRemoveMap(this.maxSleep, true);
  },
  checkAndClearKeydownupMergeRemoveMap() {
    let self = this;
    this.checkAndClearKeydownupMergeRemoveMapInterval = setInterval(() => {
      self._checkAndClearKeydownupMergeRemoveMap(this.maxSleep);
    }, 1000 / 10);
  },
  /**
   * @param sleepMillSeconds if sleepMillSeconds is 0, it means clear all keydown event right now!It might be used on destroy lifecycle
   * @private
   */
  _checkAndClearKeydownupMergeRemoveMap(sleepMillSeconds, isBlur) {
    let map = this.keydownupMergeRemoveMap;
    Object.keys(map).forEach((k) => {
      let item = map[k];
      if (+new Date() - item.t >= sleepMillSeconds || isBlur) {
        let e = new item.e.constructor('keyup', item.e); // not supported any version of IE
        try {
          this._keyMouseEventHandler(e);
        } catch (ex) {}
        delete this.keydownupMergeRemoveMap[k];
      }
    });
  },
  uncheckAndClearKeydownupMergeRemoveMap() {
    this._checkAndClearKeydownupMergeRemoveMap(0);
    clearInterval(this.checkAndClearKeydownupMergeRemoveMapInterval);
  },
  /**
   * some filters do something (eg : pre-process, block process ...) before processing specific event
   * @param e
   * @returns {Promise<T>}
   */
  keyMouseFilters(e) {
    return (
      new Promise((resolve, reject) => {
        resolve(e);
      })
        .then((e) => this.blockedPasteKeyboard(e))
        /** ZOOM-505299: ctrl/command + key not work due to single meta be blocked */
        // .then((e) => this.blockedSingleMetaKey(e))
        .then((e) => this.blockedCopyKeyboard(e))
        .then((e) => this.blockedCutKeyboard(e))
        .then((e) => this.keyMouseEventHandlerFilter_MatainKeyDownUpMergeMap(e))
        .then((e) => this.blockedSomeKeyboardEventNotHaveKeydownBefore(e))
        .then((e) =>
          this.keyMouseEventHandlerFilter_EVERY_KEYUP_MUST_HAVE_KEYDOWN_BEFORE(
            e
          )
        )
    );
  },
  /**
   * why need this ?
   * think about following scene:
   * ctrl down
   * a suite of keydown/keyup events for paste
   * ctrl up
   *
   * It will be broken if remote OS is MAC, because the suite of events are between ctrl-down and ctrl-up
   * But also :
   * this strategy will disable single ctrl keyboard event
   * @param e
   * @returns {Promise<any>}
   */
  blockedSingleMetaKey(e) {
    return new Promise((resolve, reject) => {
      if (this.isKeyboardEvent(e) && e.key) {
        if (
          /command/i.test(e.key) ||
          /meta/i.test(e.key) ||
          /control/i.test(e.key)
        ) {
          if (!e.shiftKey && !e.altKey && e.ctrlKey ^ e.metaKey) {
            return;
          }
        }
      }
      resolve(e);
    });
  },
  blockedCutKeyboard(e) {
    let self = this;
    return new Promise((resolve, reject) => {
      if (self.isKeyboardEvent(e)) {
        if (self.isCutEvent(e)) {
          return;
        }
      }

      resolve(e);
    });
  },
  blockedCopyKeyboard(e) {
    let self = this;
    return new Promise((resolve, reject) => {
      if (self.isKeyboardEvent(e)) {
        if (self.isCopyEvent(e)) {
          self._blockedCopyKeyboardList.push(e);
          return;
        }
      }

      resolve(e);
    });
  },
  blockedSomeKeyboardEventNotHaveKeydownBefore(e) {
    return new Promise((resolve, reject) => {
      if (this.isKeyboardEvent(e) && e.key) {
        let list = ['x', 'c', 'v'];
        let isInList = false;
        list.forEach((k) => {
          if (this.isThisKeyIgnoreCase(e, k)) {
            isInList = true;
          }
        });
        if (e.type === 'keyup' && isInList) {
          let isKeydownBefore =
            !!this
              .keyMouseEventHandlerFilter_every_keyup_must_have_keydown_before_Map[
              e.key
            ];
          if (!isKeydownBefore) {
            return;
          }
        }
      }
      resolve(e);
    });
  },
  /**
   * we assume that every keyup event must have keydown event right before
   * but when we test, on windows 10, Chrome
   * when user press `windows + v`   or `meta + v`
   * sdk cannot detect keydown of `v` , only keyup of `v`
   * it is very annoying and can make unpredictable error
   * @param e
   * @returns {Promise<Event>}
   */
  keyMouseEventHandlerFilter_EVERY_KEYUP_MUST_HAVE_KEYDOWN_BEFORE(e) {
    return new Promise((resolve, reject) => {
      try {
        if (this.isKeyboardEvent(e) && e.key) {
          if (e.type === 'keydown') {
            this.keyMouseEventHandlerFilter_every_keyup_must_have_keydown_before_Map[
              e.key
            ] = e;
          }

          if (e.type === 'keyup') {
            let isKeydownBefore =
              !!this
                .keyMouseEventHandlerFilter_every_keyup_must_have_keydown_before_Map[
                e.key
              ];
            delete this
              .keyMouseEventHandlerFilter_every_keyup_must_have_keydown_before_Map[
              e.key
            ];

            let whiteList = ['meta']; //'c', 'x', 'v'
            let isInWhiteList = false;
            whiteList.forEach((k) => {
              if (this.isThisKeyIgnoreCase(e, k)) {
                isInWhiteList = true;
              }
            });
            if (!isKeydownBefore && !isInWhiteList) {
              log.warn('keyup trigger, but no keydown before');
              let newEvent = new e.constructor('keydown', e); // not supported any version of IE
              this._keyMouseEventHandler(newEvent);
            }
          }
        }
      } catch (ex) {
        log.error(ex);
      }
      resolve(e);
    });
  },
  _keyMouseEventHandler(e) {
    if (this.isKeyboardEvent(e)) {
      log('_keyMouseEventHandler keyboard', e);
    }
    let obj = {};
    let keyboardEvents = ['keydown', 'keyup'];
    let mouseEvents = [
      'mousedown',
      'mouseup',
      'mousemove',
      'wheel',
      'click',
      'dblclick',
      'contextmenu',
    ];
    let inputKeyTypeMap = {
      keydown: 0,
      keyup: 1,
      keychar: 2,
    };
    let inputMouseTypeMap = {
      mousemove: 0,
      mousedown: {
        left: 1,
        right: 4,
        middle: 7,
      },
      mouseup: {
        left: 2,
        right: 5,
        middle: 8,
      },

      mouseleftdbldown: 3,
      mouserightdbldown: 6,

      wheel: 10,
    };
    let getModifierState = e.getModifierState
      ? function (keyArg) {
          return e.getModifierState(keyArg);
        }
      : function (keyArg, defaultValue) {
          return defaultValue;
        };
    let pduType = PDU_TYPE_ENUM.UNKNOWN;
    let isExistCharCode = e.key && e.key.length === 1;

    if (keyboardEvents.indexOf(e.type) !== -1) {
      obj.event_type = 1;
      pduType = PDU_TYPE_ENUM.KEYBOARD;
      inputKeyTypeMap[e.type] !== undefined &&
        (obj.input_event_type = inputKeyTypeMap[e.type]);
    } else if (mouseEvents.indexOf(e.type) !== -1) {
      obj.event_type = 0;
      this.recordCurrentMousePosition(e);
      let resolvePositon = this.resolvePosition(e);
      Object.assign(obj, {
        x: resolvePositon.x,
        y: resolvePositon.y,
      });
      pduType = e.type === 'wheel' ? PDU_TYPE_ENUM.WHEEL : PDU_TYPE_ENUM.MOUSE;
      inputMouseTypeMap[e.type] !== undefined &&
        (obj.input_event_type = inputMouseTypeMap[e.type]);
      if (['mousedown', 'mouseup'].indexOf(e.type) !== -1) {
        let direction;
        switch (e.button) {
          case 0:
            direction = 'left';
            break;
          case 1:
            direction = 'middle';
            break;
          case 2:
            direction = 'right';
            break;
          default:
            direction = 'left';
            break;
        }
        obj.input_event_type = inputMouseTypeMap[e.type][direction];
      }
      if (['dblclick'].indexOf(e.type) !== -1) {
        obj.input_event_type = inputMouseTypeMap.mouseleftdbldown;
      }
    } else {
      log.warn('the event type cannot be handled');
      return;
    }

    if (e.type === 'wheel') {
      let map = { deltaX: 'dx', deltaY: 'dy' };

      Object.keys(map).forEach((k) => {
        if (e[k]) {
          obj[map[k]] = e[k] > 0 ? -100 : 100;
        }
      });
    }

    const convertKey = this.ctrlMetaConvert({
      keyCode: e.keyCode,
      key: e.key,
      ctrlKey: e.ctrlKey,
      metaKey: e.metaKey,
    });

    Object.assign(obj, {
      t: e.type,
      pduType: pduType,
      keyCode: convertKey.keyCode,
      charCode: isExistCharCode ? e.key.charCodeAt(0) : 0,
      key: convertKey.key,
      repeat: e.repeat, //  Edge not supported
      ctrl: convertKey.ctrlKey,
      alt: e.altKey,
      super: convertKey.metaKey,
      shift: e.shiftKey,
      capslock: getModifierState('CapsLock', false),
      numlock: getModifierState('NumLock', false), // safari not supported getModifierState
    });

    if (this.isKeyboardEvent(e)) {
      log('processEvent', JSON.stringify(obj));
    }
    this.processEvent(obj);
  },
  ctrlMetaConvert(e) {
    const metaKey = e.metaKey;
    const ctrlKey = e.ctrlKey;
    if (
      (this.remoteOS === OS_ENUM.MAC && this.localOS === OS_ENUM.WIN) ||
      (this.remoteOS === OS_ENUM.WIN && this.localOS === OS_ENUM.MAC)
    ) {
      if (e.key) {
        if (/Meta/i.test(e.key)) {
          e.ctrlKey = true;
          e.keyCode = 17;
          e.metaKey = ctrlKey;
          e.key = 'Control';
        } else if (/Control/i.test(e.key)) {
          e.metaKey = true;
          e.keyCode = 91;
          e.ctrlKey = metaKey;
          e.key = 'Meta';
        }
      }
    }
    return e;
  },
  isCapslock(e) {
    var charCode = e.keyCode;
    var key = e.key;
    var shifton = e.shiftKey;

    if (!/[a-zA-Z]/.test(key)) {
      return false;
    }

    return key.charCodeAt(0) !== charCode ? !shifton : shifton;
  },
  async stop() {
    this.blobSocket && this.blobSocket.close();
    this.blobSocket = null;
  },
  async connectSocket() {
    var blobSocket = await this.createBlobSocket(
      `${this.socketURL}/wc/media/${this.meetingID}?type=r&mode=2&cid=${this.condID}`
    );
    blobSocket.binaryType = 'blob';
    this.blobSocket = blobSocket;
    return true;
  },
  socketBlobMessageHandler() {
    if (this.blobSocket) {
      let self = this;
      this.blobSocket.addEventListener('message', async function (mes) {
        let blob = mes.data;
        let buffer = await util.readBlobAsBuffer(blob, blob.size);
        let int8arr = new Int8Array(buffer);
        let firstByte = int8arr[0];

        if (
          firstByte === PDU_TYPE_ENUM.COPY_TEXT_FROM_RWG &&
          // security issue: ZOOM-461343(should not handle attacker's fake message when control is end)
          self.isControllerNow
        ) {
          let blobSlice = blob.slice(8);
          let content = await util.readBlob(blobSlice);
          let len = int8arr[4];

          log('COPY_TEXT_FROM_RWG', content, len);

          self.triggerReturnCopiedText({
            data: content,
            x: self.currentMousePositionProps.x2video,
            y: self.currentMousePositionProps.y2video,
          });
        }

        if (firstByte === PDU_TYPE_ENUM.KEEP_ALIVE) {
          self.blobSocket.send(buffer);
        }
      });
    }
  },
  destroySocket() {
    try {
      this.blobSocket.close();
      this.blobSocket = null;
    } catch (ex) {
      log('destroySocket', ex);
    }
  },
  createBlobSocket(url) {
    return new Promise((resolve, reject) => {
      var socket = new WebSocket(url);
      socket.addEventListener('open', () => {});
      socket.addEventListener('message', (evt) => {
        try {
          let json = JSON.parse(evt.data);

          if (json.evt === 0 && json.body) {
            resolve(socket);
          }
        } catch (ex) {}
      });
      socket.addEventListener('error', (ex) => {
        reject(ex);
      });
      socket.addEventListener('close', () => {
        reject(new Error('socket close'));
      });
    });
  },
  setRemoteOS(os) {
    if (os !== undefined) {
      this.remoteOS = os;
    }
  },
};

export default RemoteControl;
