import util from '../common/util';
import globalTracingLogger from '../common/globalTracingLogger';
import jsMediaEngineVariables from './JsMediaEngine_Variables';
import * as jsEvent from '../common/jsEvent';
import PubSub from '../common/pubSub';
import Zoom_Monitor from './Monitor';
import { Notify_Video_Decode_Thread } from '../lib/JsMediaEngine';

const audioBridgeMonitor = (log, e) => Zoom_Monitor.add_monitor('AB' + log);
const isSupportOffscreenCanvas = util.isSupportOffscreenCanvas();
const shareAudioMask = 0x200;
const safariVersion = util.getMacSafariMajorVersion();
const isSafariVersionLowerThan16 =
  util.browser.isSafari && safariVersion && safariVersion < 16;

const audioRecvQualityMonitor = (() => {
  let monitorInfoList = [];
  let prevTime = Date.now();
  return (monitorInfo) => {
    monitorInfoList.push(monitorInfo);
    if (Date.now() - prevTime > 14 * 1000) {
      const log = monitorInfoList.reduce((prev, now, idx) => {
        const { rtt, ssrcMap } = now;
        let ssrcLog = '';
        Object.keys(ssrcMap).forEach((key) => {
          const {
            aveAudioLevel,
            audioLevel,
            aveJitterBufferDelay,
            jitter,
            packetsLost,
            packetsReceived,
            bytesReceived,
          } = ssrcMap[key];
          ssrcLog += `{${key}},${audioLevel},${aveAudioLevel},${jitter},${aveJitterBufferDelay},${packetsLost},${packetsReceived},${bytesReceived}`;
        });
        return (
          prev +
          `${rtt || ''}${ssrcLog ? `,${ssrcLog}` : ''}` +
          (idx < monitorInfoList.length - 1 ? '||' : '')
        );
      }, 'WCL_AB, {[NETWORK]},');
      prevTime = Date.now();
      jsMediaEngineVariables.sendMessageToRwg(jsEvent.MONITOR_LOG, {
        evt: 4167,
        seq: 1,
        body: {
          data: log,
        },
      });
      monitorInfoList = [];
    }
  };
})();

const audioSendQualityMonitor = (() => {
  let monitorInfoList = [];
  let prevTime = Date.now();
  return (monitorInfo) => {
    monitorInfoList.push(monitorInfo);
    if (Date.now() - prevTime > 14 * 1000) {
      const log = monitorInfoList.reduce((prev, now, idx) => {
        const { rtt, bytesSent } = now;
        return (
          prev +
          `${rtt || ''},${bytesSent === undefined ? '' : bytesSent}` +
          (idx < monitorInfoList.length - 1 ? '||' : '')
        );
      }, 'WCL_AB, {[SEND]},');
      prevTime = Date.now();
      jsMediaEngineVariables.sendMessageToRwg(jsEvent.MONITOR_LOG, {
        evt: 4167,
        seq: 1,
        body: {
          data: log,
        },
      });
      monitorInfoList = [];
    }
  };
})();

const configuration = {
  bundlePolicy: 'max-bundle',
  rtcpMuxPolicy: 'require',
  sdpSemantics: 'unified-plan',
  iceServers: [],
  iceTransportPolicy: 'all',
};

const WEB_RTC_RECONNECT_TIMEOUT = 5000;

let ucid;

export function addUserEventListener(callback) {
  const b = document.body;
  const events = [
    'click',
    'contextmenu',
    'auxclick',
    'dblclick',
    'mousedown',
    'mouseup',
    'touchend',
    'keydown',
    'keyup',
  ];
  events.forEach((e) => b.addEventListener(e, unlock, false));
  function unlock() {
    callback();
  }
  return function clean() {
    events.forEach((e) => b.removeEventListener(e, unlock));
  };
}

export default class WebrtcAudioBridge {
  constructor(nginxHost, rwgHost, cid, recvOnly, isMultiView, supportLocalAB) {
    this.publisherCandidate = [];
    this.subscriberCandidate = [];
    this.retry = 0;
    this.retryPublish = 0;
    this.nginxHost = nginxHost;
    this.rwgHost = rwgHost;
    this.conId = cid;
    if (supportLocalAB) {
      this.wsUrl = `wss://${rwgHost}/ab/signal?rwg=${rwgHost}&cid=${cid}`;
    } else {
      this.wsUrl = `wss://${nginxHost}/ab/signal?rwg=${rwgHost}&cid=${cid}`;
    }
    this.audioPlayerMap = new Map();
    this.onError = null;
    this.isRetrying = false;
    this.recvOnly = !!recvOnly;
    /** create an fake audio stream track */
    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    this.destination = this.audioCtx.createMediaStreamDestination();
    this.monitorTimer = null;
    this.monitorTimerDuration = 5 * 1000;
    this.monitorInfo = {
      subscriber: {},
      publisher: {},
    };
    this.muted = false;
    this.audioMuteStatus = new Map();
    this.ssrcUserIdMap = new Map();
    this.published = false;
    this.joinAudioAfterConnect = false;
    this.isJoinAudio = false;
    this.isMultiView = !!isMultiView;
    this.normalAudioMap = new Map();
    this.shareAudioMap = new Map();
    this.syncTimer = null;
    if (isSafariVersionLowerThan16) {
      this.monitorAudio = new Audio();
    }
    this.hasPaused = false;
    this.cleanUserEventListener = addUserEventListener(
      this.playAllRemoteAudio.bind(this)
    );
    document.addEventListener('visibilitychange', () => {
      if (
        document.visibilityState === 'visible' &&
        this.hasPaused &&
        this.isJoinAudio
      ) {
        this.playAllRemoteAudio();
      }
    });
  }

  playAllRemoteAudio() {
    if (this.hasPaused && this.isJoinAudio) {
      if (util.isIphoneOrIpadSafari()) {
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: false })
          .then(() => audioBridgeMonitor('RECOK'))
          .catch(() => audioBridgeMonitor('RECERR'));
      }
      let recoverList = Array.from(this.audioPlayerMap.values()).map(
        (audioPlayer) => {
          if (audioPlayer.paused) {
            return audioPlayer.play();
          }
        }
      );
      Promise.all(recoverList)
        .then(() => {
          this.hasPaused = false;
        })
        .catch((e) => {
          this.hasPaused = true;
          if (jsMediaEngineVariables.Notify_APPUI) {
            audioBridgeMonitor('REA');
            jsMediaEngineVariables.Notify_APPUI(jsEvent.RECOVER_WEBRTC_AUDIO);
          }
        });
    }
  }

  async reconnect(err) {
    if (this.isRetrying || this.isDestroyed) {
      return;
    }
    if (this.retry >= 3) {
      this.isRetrying = false;
      console.error('audio bridge:::: reconnect by error: ', err);
      if (jsMediaEngineVariables.Notify_APPUI) {
        jsMediaEngineVariables.Notify_APPUI(
          jsEvent.WCL_SIP_WEBSOCKET_CONNECT_ERROR
        );
      }
      return;
    }
    this.isRetrying = true;
    await util.sleep(3000);
    if (this.isDestroyed) return;
    audioBridgeMonitor('JOIN' + this.retry);
    this.retry++;
    if (jsMediaEngineVariables.Notify_APPUI) {
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.WCL_AUDIO_BRIDGE_RECONNECT_START
      );
    }
    if (this.publisher) {
      if (this.sender) {
        this.publisher.removeTrack(this.sender);
        this.sender = null;
      }
      this.publisher.close();
      this.publisher = null;
    }
    if (this.subscriber) {
      this.subscriber.close();
      this.subscriber = null;
    }
    await this.join(true);
    this.isRetrying = false;
  }

  async join(isRetry, abToken, joinAudio = false) {
    this.stopDestoryOldSignalTimer();
    this.isJoinAudio = joinAudio;

    if (this.signal) {
      this.isDestroyed = false;
      this.signal.isDestroyed = false;
      this.signal.isJoinAudio = joinAudio;
      if (joinAudio) {
        this.startAVSyncTimer();
        if (this.signal.socket && this.signal.socket.readyState === 1)
          this.signal.notify('audiostatus', { status: 1 });
      }
      this.startAudioQualityMonitorTimer();
      this.unmuteAllRemoteAudio();
      return;
    }
    //if websocket is reconnecting when join audio, set join audio after retry success
    if (!isRetry) {
      this.joinAudioAfterConnect = joinAudio;
      if (this.isRetrying) {
        this.destroySocketAndWebRtcConnect(isRetry);
        this.isDestroyed = false;
        return;
      }
    }
    this.destroySocketAndWebRtcConnect(isRetry);
    this.isDestroyed = false;
    audioBridgeMonitor(
      'INIT' +
        `,${this.nginxHost}` +
        `,${this.rwgHost}` +
        `,${this.conId}` +
        `,${abToken}`
    );
    this.signal = new JsonRPCSignal(this.joinAudioAfterConnect);
    if (this.joinAudioAfterConnect) this.startAVSyncTimer();
    this.joinAudioAfterConnect = false;

    try {
      let token;
      if (abToken) {
        token = abToken;
      } else {
        jsMediaEngineVariables.sendMessageToRwg(
          jsEvent.REQUEST_AUDIO_BRIDGE_TOKEN,
          { evt: jsEvent.WS_CONF_AB_TOKEN_REQ },
          false
        );
        token = await this.waitForTokenFromRWG(
          jsEvent.PUBSUB_EVT.AUDIO_BRIDGE_WS_TOKEN
        );
        audioBridgeMonitor('TK');
      }
      this.signal.init(`${this.wsUrl}&token=${token}`);
    } catch (e) {
      this.reconnect(e);
    }

    this.signal.onopen(async (evt) => {
      this.startAudioQualityMonitorTimer();
      this.isOpen = true;
      this.retryPublish = 0;
      if (this.audioStream || this.recvOnly) {
        this.clearAudioPlayerMap();
        this.setAudioStream(this.audioStream);
        await this.publish();
        this.retry = 0;
        if (jsMediaEngineVariables.Notify_APPUI && this.signal.isJoinAudio) {
          jsMediaEngineVariables.Notify_APPUI(
            jsEvent.WCL_AUDIO_BRIDGE_RECONNECT_END
          );
        }
      }
    });

    this.signal.onclose((e) => {
      this.signal = null;
      this.published = false;
      this.stopAudioQualityMonitorTimer();
      this.stopAVSyncTimer();
      if (e) {
        this.reconnect(e);
      }
    });

    this.signal.onerror((e) => {
      this.signal = null;
      this.published = false;
      this.stopAudioQualityMonitorTimer();
      this.stopAVSyncTimer();
      if (e) {
        this.reconnect(e);
      }
    });

    this.signal.on_notify('command', (params) => {
      const { data } = params || {};
      if (data) {
        try {
          const res = JSON.parse(data);
          const { evt } = res || {};
          if (evt === jsEvent.WS_CONF_END_INDICATION) {
            this.destroy(false);
          }
        } catch (e) {}
      }
    });
    this.signal.UpdateNTP = (baseNTP, baseRTP, ssrc, abssrc) => {
      if (ssrc & shareAudioMask) {
        this.shareAudioMap.set(ssrc >> 10, {
          ntptime: baseNTP,
          rtptime: baseRTP,
          abssrc: abssrc,
        });
      } else {
        this.normalAudioMap.set(ssrc >> 10, {
          ntptime: baseNTP,
          rtptime: baseRTP,
          abssrc: abssrc,
        });
      }

      return;
    };

    if (this.publisher && this.sender) {
      this.publisher.removeTrack(this.sender);
      this.sender = null;
    }

    this.publisher = new RTCPeerConnection(configuration);
    this.subscriber = new RTCPeerConnection(configuration);

    this.signal.onOffer = async (offer) => {
      audioBridgeMonitor('RERSDP');
      this.updateSsrcUserIdMap(offer);
      await this.subscriber.setRemoteDescription(offer);
      this.subscriberCandidate.forEach((candidate) =>
        this.subscriber.addIceCandidate(candidate)
      );
      this.subscriberCandidate = [];

      const sdp = await this.subscriber.createAnswer();
      await this.subscriber.setLocalDescription(sdp);
      audioBridgeMonitor('RELSDP');
      await this.rpc('answer', { desc: sdp });
    };

    this.signal.onTrickle = async (candidate, role) => {
      if (role == 0) {
        if (this.subscriber.remoteDescription != null) {
          audioBridgeMonitor('RERICE');
          this.subscriber.addIceCandidate(candidate);
        } else {
          audioBridgeMonitor('RERICEF');
          this.subscriberCandidate.push(candidate);
        }
      } else if (role == 1) {
        if (this.publisher.remoteDescription != null) {
          audioBridgeMonitor('SERICE');
          this.publisher.addIceCandidate(candidate);
        } else {
          audioBridgeMonitor('SERICEF');
          this.publisherCandidate.push(candidate);
        }
      } else {
        console.error(
          'audio bridge:::: onTrickle ERROR!!!! role:',
          role,
          ', candidate:',
          candidate
        );
      }
    };

    this.publisher.onicecandidate = ({ candidate }) => {
      audioBridgeMonitor('SELICE');
      this.signal.notify('trickle', { candidate: candidate, role: 1 });
    };

    this.publisher.onconnectionstatechange = () => {
      audioBridgeMonitor('PCSC:' + this.publisher.connectionState);
      if (this.publisher) {
        if (this.publisher.connectionState === 'disconnected') {
          setTimeout(() => {
            if (
              this.publisher &&
              this.publisher.connectionState === 'disconnected'
            ) {
              this.publish(true);
            }
          }, WEB_RTC_RECONNECT_TIMEOUT);
        } else if (this.publisher.connectionState === 'connected') {
          this.signal.isRtcConnected = true;
          if (!this.publisher.firstConnected) {
            this.publisher.firstConnected = true;
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.AUDIO_BRIDGE_CAN_SEND_DATA
            );
          }
        }
      }
    };

    this.subscriber.onicecandidate = ({ candidate }) => {
      audioBridgeMonitor('RELICE');
      this.signal.notify('trickle', { candidate: candidate, role: 0 });
    };

    this.subscriber.ontrack = (event) => {
      audioBridgeMonitor('REVT');
      const stream = event.streams[0];

      const streamId = decodeURIComponent(stream.id);
      const audioId = 'ab-audio-' + streamId;
      event.track.onunmute = () => {
        let audioPlayer = this.audioPlayerMap.get(streamId);
        if (audioPlayer) {
          audioPlayer.srcObject = stream;
        } else {
          audioPlayer = document.createElement('audio');
          audioPlayer.addEventListener('playing', () => {
            audioBridgeMonitor('ASP:' + audioId);
          });

          audioPlayer.addEventListener('pause', () => {
            audioBridgeMonitor('APP:' + audioId);
            audioPlayer.pause();
            audioPlayer.removeAttribute('autoplay');
            this.hasPaused = true;
          });

          audioPlayer.addEventListener('canplay', () => {
            audioBridgeMonitor('ACP:' + audioId);
          });

          audioPlayer.id = audioId;
          audioPlayer.srcObject = stream;
          audioPlayer.autoplay = true;
          audioPlayer.controls = false;
          this.audioPlayerMap.set(streamId, audioPlayer);
          document.documentElement.appendChild(audioPlayer);
        }
        if (this.isDestroyed) {
          audioPlayer.muted = true;
        }
        audioBridgeMonitor('REVTP');
      };
      event.track.onmute = () => {
        audioBridgeMonitor('REVTM');
        const audioPlayer = this.audioPlayerMap.get(streamId);
        if (audioPlayer) {
          audioPlayer.remove();
        }
        this.audioPlayerMap.delete(streamId);
      };
    };

    this.subscriber.onconnectionstatechange = () => {
      audioBridgeMonitor('SCSC:' + this.subscriber.connectionState);
      if (
        this.subscriber &&
        this.subscriber.connectionState === 'connected' &&
        !this.subscriber.firstConnected
      ) {
        this.subscriber.firstConnected = true;
        jsMediaEngineVariables.Notify_APPUI(
          jsEvent.AUDIO_BRIDGE_FIRST_RECV_DATA
        );
      }
    };
  }

  leaveAudioWithoutDisconnect() {
    this.isJoinAudio = false;
    if (this.signal && this.signal.isJoinAudio) {
      this.signal.isJoinAudio = false;
      this.signal.leaveTime = Date.now();
      this.signal.notify('audiostatus', { status: 0 });
    }

    this.setAudioStream(null, true);
    this.muteAllRemoteAudio();

    this.stopAudioQualityMonitorTimer();
    this.stopAVSyncTimer();
  }

  async rpc(method, params = []) {
    try {
      if (!this.signal) {
        console.error(
          'audio bridge:::: RPC ERROR, signal NULL',
          method,
          params
        );
        return '';
      }
      return this.signal.call(method, params);
    } catch (err) {
      console.error('audio bridge:::: RPC err', method, params, err);
      return '';
    }
  }

  async publish(retry) {
    if (!this.publisher || !this.isOpen) {
      return;
    }

    if (retry) {
      if (this.retryPublish >= 3) {
        return;
      }
      this.retryPublish++;
    }

    if (!retry) {
      if (this.published) return;
      this.published = true;
    }

    let offer = await this.publisher.createOffer({
      iceRestart: !!retry,
    });

    offer.sdp = offer.sdp.replace(
      'a=fmtp:111 minptime=10;useinbandfec=1',
      'a=fmtp:111 minptime=10;useinbandfec=1;maxaveragebitrate=48000;maxplaybackrate=24000;sprop-maxcapturerate=24000'
    );

    await this.publisher.setLocalDescription(offer);

    if (!this.uid && !retry) {
      this.uid = uuidv4();
    }
    audioBridgeMonitor('SELSDP');
    const res = await (retry
      ? this.rpc('offer', { desc: this.publisher.localDescription })
      : this.rpc('join', {
          uid: this.uid,
          offer: this.publisher.localDescription,
        }));
    if (res && res.length === 2) {
      ucid = res[0];
      const jsep = res[1];
      if (jsep.type === 'answer') {
        audioBridgeMonitor('SERSDP');
        await this.publisher.setRemoteDescription(jsep);
        this.publisherCandidate.forEach((candidate) =>
          this.publisher.addIceCandidate(candidate)
        );
        this.publisherCandidate = [];
        this.setAudioStream(this.audioStream);
        this.ping();
      }
    }
  }

  waitForTokenFromRWG(pubSubEvent) {
    return new Promise((resolve, reject) => {
      PubSub.on(pubSubEvent, (msg, data) => {
        resolve(data);
      });
    });
  }

  ping() {
    if (this.signal != null) {
      this.signal.notify('ping', {});
    }
    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout);
    }
    this.pingTimeout = setTimeout(() => {
      this.ping();
    }, 15000);
  }

  /** allow to talk or start to talk when join meeting from preview page */
  setRecvOnly(bool, audioStream) {
    this.recvOnly = !!bool;
    if (this.recvOnly) {
      this.setAudioStream(null, true);
    } else {
      if (audioStream) {
        this.setAudioStream(audioStream);
      }
    }
  }

  setAudioStream(stream, replaceFakeAudio) {
    if (this.recvOnly || replaceFakeAudio) {
      this.audioStream = this.destination.stream;
    } else {
      this.audioStream = stream;
    }
    if (this.monitorAudio) {
      this.monitorAudio.srcObject = this.audioStream;
      this.monitorAudio.muted = true;
      if (this.monitorAudio.paused) {
        this.monitorAudio.play();
      }
    }

    let trueTrack = null;
    if (
      !this.audioStream ||
      this.audioStream.id === this.destination.stream.id
    ) {
      trueTrack = 'F';
    } else {
      trueTrack = 'T';
    }

    if (this.publisher) {
      this.audioStream.getTracks().forEach((track) => {
        if (this.sender) {
          audioBridgeMonitor('PRAT' + trueTrack);
          this.sender.replaceTrack(track).catch((error) => {
            audioBridgeMonitor('RATE');
          });
        } else {
          audioBridgeMonitor('PAAT' + trueTrack);
          this.sender = this.publisher.addTrack(track, this.audioStream);
        }
      });
    }
  }

  mute() {
    if (this.audioStream) {
      audioBridgeMonitor('SEVTP');
      this.muted = true;
      this.audioStream.getAudioTracks().forEach((track) => {
        track.enabled = false;
      });
    }
  }

  unmute() {
    if (this.audioStream) {
      audioBridgeMonitor('SEVTM');
      this.muted = false;
      this.audioStream.getAudioTracks().forEach((track) => {
        track.enabled = true;
      });
    }
  }

  stopIncomingAudio(enable) {
    if (enable) {
      this.muteAllRemoteAudio();
    } else {
      this.unmuteAllRemoteAudio();
    }
  }

  muteAllRemoteAudio() {
    for (const [streamId, audioPlayer] of this.audioPlayerMap) {
      audioPlayer.muted = true;
    }
  }

  unmuteAllRemoteAudio() {
    for (const [streamId, audioPlayer] of this.audioPlayerMap) {
      audioPlayer.muted = false;
    }
    this.playAllRemoteAudio();
  }

  /** local set others' audio volume */
  setSpeechVolumeLevel(userId, volume) {
    for (const [streamId, audioPlayer] of this.audioPlayerMap) {
      const id = Number(streamId.split('+')[0] || '0');
      const ssrc = Number.isNaN(id) || !id ? null : (id >> 10) << 10;
      if (ssrc && ssrc === (userId >> 10) << 10) {
        const level = Math.min(Math.max(0, volume), 100) / 100;
        audioPlayer.volume = level;
        break;
      }
    }
  }

  changeSpeaker(speaker) {
    for (const [streamId, audioPlayer] of this.audioPlayerMap) {
      audioPlayer.setSinkId(speaker).catch((e) => {
        globalTracingLogger.error('Error when setting sink of audio player', e);
      });
    }
  }

  /** store webrtc ssrc -> zoom userId map */
  updateSsrcUserIdMap(offer) {
    if (offer) {
      const { sdp = '' } = offer;
      const matches = sdp.match(/a=ssrc:(.+) cname:(.+)/g);
      if (matches) {
        matches.forEach((item) => {
          const match = item.match(/a=ssrc:(.+) cname:(.+)/);
          if (match && match[1] && match[2]) {
            const id = Number(match[2].split('+')[0] || '0');
            const userId = Number.isNaN(id) || !id ? null : (id >> 10) << 10;
            const newSsrc = Number(match[1]);
            this.ssrcUserIdMap.set(newSsrc, userId);
          }
        });
      }
    }
  }

  /** store user mute/unmute status */
  updateUserMuteUnmuteStatus(data) {
    const { update, remove } = data;
    if (update && update.length > 0) {
      update.forEach((user) => {
        const { userId, muted } = user;
        if (userId) {
          this.audioMuteStatus.set((userId >> 10) << 10, !!muted);
        }
      });
    }
    if (remove && remove.length > 0) {
      remove.forEach((user) => {
        const { userId } = user;
        if (userId) {
          this.audioMuteStatus.set((userId >> 10) << 10, true);
        }
      });
    }
  }

  /** get target ssrc mute status */
  isUserMuted(ssrc) {
    const userId = this.ssrcUserIdMap.get(ssrc);
    if (!userId) return false;
    const muted = this.audioMuteStatus.get(userId);
    if (muted === undefined) return false;
    return !!muted;
  }

  clearAudioPlayerMap() {
    this.muteAllRemoteAudio();
    for (const [streamId, audioPlayer] of this.audioPlayerMap) {
      audioPlayer.remove();
    }
    this.audioPlayerMap.clear();
    this.audioMuteStatus.clear();
  }

  destroySocketAndWebRtcConnect(isRetry) {
    this.published = false;
    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout);
      this.pingTimeout = null;
    }

    // should replace track here
    try {
      if (this.sender) {
        this.publisher.removeTrack(this.sender);
        this.sender = null;
      }
    } catch (e) {}

    if (this.publisher) {
      this.publisher.close();
      this.publisher = null;
    }
    if (this.subscriber) {
      this.subscriber.close();
      this.subscriber = null;
    }

    this.isOpen = false;
    if (!isRetry) {
      this.retry = 0;
    }
    this.retryPublish = 0;

    if (this.signal) {
      this.signal.isDestroyed = true;
      this.signal.destroy();
      this.signal = null;
    }
    this.ssrcUserIdMap.clear();
  }

  stopDestoryOldSignalTimer() {
    if (this.destroyOldSignalTimer) {
      clearTimeout(this.destroyOldSignalTimer);
      this.destroyOldSignalTimer = null;
    }
  }

  startDestoryOldSignalTimer() {
    this.stopDestoryOldSignalTimer();
    this.destroyOldSignalTimer = setTimeout(() => {
      this.stopDestoryOldSignalTimer();
      this.destroySocketAndWebRtcConnect();
    }, 15 * 1000);
  }

  destroy(keepSocket = true) {
    audioBridgeMonitor('DS');
    this.cleanUserEventListener();
    this.isDestroyed = true;
    if (this.signal) {
      this.signal.isDestroyed = true;
    }
    this.stopAudioQualityMonitorTimer();
    this.stopAVSyncTimer();
    this.stopDestoryOldSignalTimer();
    if (keepSocket) {
      this.muteAllRemoteAudio();
      /** replace send stream with fake audio stream */
      this.setAudioStream(null, true);
      this.startDestoryOldSignalTimer();
    } else {
      this.clearAudioPlayerMap();
      this.destroySocketAndWebRtcConnect();
      if (this.audioCtx) {
        this.audioCtx.close();
        this.audioCtx = null;
        this.destination = null;
        this.recvOnly = false;
      }
    }
  }

  startAudioQualityMonitorTimer() {
    this.stopAudioQualityMonitorTimer();
    this.monitorTimer = setInterval(() => {
      if (this.publisher) {
        this.publisher.getStats().then((stats) => {
          if (stats) {
            let statInfo = {};
            let shouldeSendMonitor = false;
            const { publisher: publisherInfo } = this.monitorInfo;
            stats.forEach((stat) => {
              const {
                totalRoundTripTime,
                responsesReceived,
                bytesSent,
                timestamp,
                localCandidateId,
                remoteCandidateId,
                type,
                state,
                nominated,
              } = stat;
              if (
                type === 'candidate-pair' &&
                state === 'succeeded' &&
                nominated
              ) {
                if (
                  !publisherInfo.prevStatInfo ||
                  publisherInfo.localCandidateId !== localCandidateId ||
                  publisherInfo.remoteCandidateId !== remoteCandidateId
                ) {
                  publisherInfo.localCandidateId = localCandidateId;
                  publisherInfo.remoteCandidateId = remoteCandidateId;
                  publisherInfo.prevStatInfo = {};
                } else {
                  const { prevStatInfo } = publisherInfo;
                  shouldeSendMonitor = true;
                  Object.assign(statInfo, {
                    rtt: Math.floor(
                      (responsesReceived === prevStatInfo.responsesReceived
                        ? 0
                        : (totalRoundTripTime -
                            prevStatInfo.totalRoundTripTime) /
                          (responsesReceived -
                            prevStatInfo.responsesReceived)) * 1000
                    ),
                    bytesSent: this.muted
                      ? 0
                      : Math.floor(
                          ((bytesSent - prevStatInfo.bytesSent) /
                            (timestamp - prevStatInfo.timestamp)) *
                            1000
                        ),
                  });
                }
                Object.assign(publisherInfo.prevStatInfo, {
                  totalRoundTripTime,
                  responsesReceived,
                  bytesSent,
                  timestamp,
                });
              }
            });
            stats.forEach((stat) => {
              const { id, type } = stat;
              if (
                type === 'local-candidate' &&
                id === publisherInfo.localCandidateId
              ) {
                const { ip, port, networkType } = stat;
                if (
                  !publisherInfo.localCA ||
                  publisherInfo.localCA.ip !== ip ||
                  publisherInfo.localCA.port !== port ||
                  publisherInfo.localCA.networkType !== networkType
                ) {
                  publisherInfo.localCA = {
                    ip,
                    port,
                    networkType,
                  };
                  audioBridgeMonitor(
                    'SELCA' + `,${ip}` + `,${port}` + `,${networkType}`
                  );
                }
              } else if (
                type === 'remote-candidate' &&
                id === publisherInfo.remoteCandidateId
              ) {
                const { ip, port } = stat;
                if (
                  !publisherInfo.remoteCA ||
                  publisherInfo.remoteCA.ip !== ip ||
                  publisherInfo.remoteCA.port !== port
                ) {
                  publisherInfo.remoteCA = {
                    ip,
                    port,
                  };
                  audioBridgeMonitor('SERCA' + `,${ip}` + `,${port}`);
                }
              }
            });
            if (shouldeSendMonitor) {
              audioSendQualityMonitor(statInfo);
            }
          }
        });
      }
      if (this.subscriber) {
        this.subscriber.getStats().then((stats) => {
          if (stats) {
            const { subscriber: subscriberInfo } = this.monitorInfo;
            let statInfo = {
              ssrcMap: {},
            };
            let shouldeSendMonitor = false;
            stats.forEach((stat) => {
              const {
                totalRoundTripTime,
                responsesReceived,
                localCandidateId,
                remoteCandidateId,
                type,
                state,
                nominated,
              } = stat;
              if (
                type === 'candidate-pair' &&
                state === 'succeeded' &&
                nominated
              ) {
                if (
                  !subscriberInfo.prevStatInfo ||
                  subscriberInfo.localCandidateId !== localCandidateId ||
                  subscriberInfo.remoteCandidateId !== remoteCandidateId
                ) {
                  subscriberInfo.localCandidateId = localCandidateId;
                  subscriberInfo.remoteCandidateId = remoteCandidateId;
                  subscriberInfo.prevStatInfo = {};
                  subscriberInfo.ssrcMap = {};
                } else {
                  const { prevStatInfo } = subscriberInfo;
                  shouldeSendMonitor = true;
                  Object.assign(statInfo, {
                    rtt: Math.floor(
                      (responsesReceived === prevStatInfo.responsesReceived
                        ? 0
                        : (totalRoundTripTime -
                            prevStatInfo.totalRoundTripTime) /
                          (responsesReceived -
                            prevStatInfo.responsesReceived)) * 1000
                    ),
                  });
                }
                Object.assign(subscriberInfo.prevStatInfo, {
                  totalRoundTripTime,
                  responsesReceived,
                });
              }
            });
            stats.forEach((stat) => {
              const { id, type } = stat;
              if (
                type === 'local-candidate' &&
                id === subscriberInfo.localCandidateId
              ) {
                const { ip, port, networkType } = stat;
                if (
                  !subscriberInfo.localCA ||
                  subscriberInfo.localCA.ip !== ip ||
                  subscriberInfo.localCA.port !== port ||
                  subscriberInfo.localCA.networkType !== networkType
                ) {
                  subscriberInfo.localCA = {
                    ip,
                    port,
                    networkType,
                  };
                  audioBridgeMonitor(
                    'RELCA' + `,${ip}` + `,${port}` + `,${networkType}`
                  );
                }
              } else if (
                stat.type === 'remote-candidate' &&
                id === subscriberInfo.remoteCandidateId
              ) {
                const { ip, port } = stat;
                if (
                  !subscriberInfo.remoteCA ||
                  subscriberInfo.remoteCA.ip !== ip ||
                  subscriberInfo.remoteCA.port !== port
                ) {
                  subscriberInfo.remoteCA = {
                    ip,
                    port,
                  };
                  audioBridgeMonitor('RERCA' + `,${ip}` + `,${port}`);
                }
              } else if (type === 'inbound-rtp') {
                const {
                  totalAudioEnergy,
                  timestamp,
                  jitterBufferDelay,
                  jitterBufferEmittedCount,
                  ssrc,
                  jitter,
                  audioLevel,
                  packetsLost,
                  packetsReceived,
                  bytesReceived,
                } = stat;
                if (!subscriberInfo.ssrcMap) {
                  subscriberInfo.ssrcMap = {};
                }
                let prevInfo = subscriberInfo.ssrcMap[ssrc];
                if (!prevInfo) {
                  prevInfo = subscriberInfo.ssrcMap[ssrc] = {};
                } else {
                  const userId = this.ssrcUserIdMap.get(ssrc);
                  if (userId) {
                    shouldeSendMonitor = true;
                    const _aveAudioLevel = Math.sqrt(
                      (totalAudioEnergy - prevInfo.totalAudioEnergy) /
                        (timestamp - prevInfo.timestamp)
                    ).toFixed(4);

                    let _audioLevel = -1;
                    //there is no audioLevel in stat sometimes
                    if (typeof audioLevel === 'number') {
                      _audioLevel = audioLevel.toFixed(4);
                    }
                    const _aveJitterBufferDelay = Math.floor(
                      (jitterBufferEmittedCount ===
                      prevInfo.jitterBufferEmittedCount
                        ? 0
                        : (jitterBufferDelay - prevInfo.jitterBufferDelay) /
                          (jitterBufferEmittedCount -
                            prevInfo.jitterBufferEmittedCount)) * 1000
                    );
                    statInfo.ssrcMap[`${ssrc}-${userId}`] = Object.assign(
                      statInfo.ssrcMap[`${ssrc}-${userId}`] || {},
                      {
                        aveAudioLevel:
                          _aveAudioLevel < 0.0001 ? 0 : _aveAudioLevel,
                        audioLevel: _audioLevel < 0.0001 ? 0 : _audioLevel,
                        aveJitterBufferDelay: _aveJitterBufferDelay,
                        jitter,
                        packetsLost: packetsLost - prevInfo.packetsLost,
                        packetsReceived: Math.floor(
                          ((packetsReceived - prevInfo.packetsReceived) /
                            (timestamp - prevInfo.timestamp)) *
                            1000
                        ),
                        bytesReceived: Math.floor(
                          ((bytesReceived - prevInfo.bytesReceived) /
                            (timestamp - prevInfo.timestamp)) *
                            1000
                        ),
                      }
                    );
                  }
                }
                if (this.isUserMuted(ssrc) || !this.ssrcUserIdMap.has(ssrc)) {
                  subscriberInfo.ssrcMap[ssrc] = null;
                } else {
                  Object.assign(prevInfo, {
                    totalAudioEnergy,
                    timestamp,
                    jitterBufferDelay,
                    jitterBufferEmittedCount,
                    packetsLost,
                    packetsReceived,
                    bytesReceived,
                  });
                }
              }
            });
            if (shouldeSendMonitor) {
              audioRecvQualityMonitor(statInfo);
            }
          }
        });
      }
    }, this.monitorTimerDuration);
  }

  stopAudioQualityMonitorTimer() {
    if (this.monitorTimer) {
      clearInterval(this.monitorTimer);
      this.monitorTimer = null;
      this.monitorInfo = {
        subscriber: {},
        publisher: {},
      };
    }
  }

  publishStream(stream) {
    this.setAudioStream(stream);
    return new Promise((resolve, reject) => {
      if (this.published) {
        resolve(true);
      } else {
        this.publish().then(() => {
          resolve(false);
        });
      }
    });
  }

  stopAVSyncTimer() {
    if (this.syncTimer) {
      clearInterval(this.syncTimer);
      this.syncTimer = null;
    }
  }

  syncSingleView(shareAudio, playedNTPTime) {
    let realNtp = 0;
    if (playedNTPTime) {
      let result = parseInt(playedNTPTime).toString(2);
      let result1 = result.substring(0, 32);
      let result2 = result.substring(32, 64);
      result1 = parseInt(result1, 2);
      result2 = parseInt(result2, 2);
      realNtp = result1 * 1000 + (result2 * 232.8) / 1000000000;
    }
    if (isSupportOffscreenCanvas) {
      //post to video worker
      Notify_Video_Decode_Thread({
        command: 'audioDecodeTime',
        status: 0,
        data: realNtp,
      });
    } else {
      //set data in main worker
      if (shareAudio) {
        if (jsMediaEngineVariables.mediaSDKHandle.SharingRenderObj)
          jsMediaEngineVariables.mediaSDKHandle.SharingRenderObj.SetcATimeStamp(
            realNtp
          );
      } else {
        if (jsMediaEngineVariables.CurrentSSRCTime != realNtp) {
          jsMediaEngineVariables.CurrentSSRCTime = realNtp;
          jsMediaEngineVariables.audioPlayTime = Date.now();
        }
      }
    }
  }

  startAVSyncTimer() {
    this.stopAVSyncTimer();
    this.syncTimer = setInterval(() => {
      if (!this.subscriber) return;
      let audioReportCount = 0;
      let receivers = this.subscriber.getReceivers();
      receivers.forEach((receiver) => {
        let reports = receiver.getSynchronizationSources();
        reports.forEach((data) => {
          audioReportCount++;
          let userId = this.ssrcUserIdMap.get(data.source);
          let baseNTP_RTP = null;
          let isShareAudio = false;

          baseNTP_RTP = this.shareAudioMap.get(userId >> 10);
          if (baseNTP_RTP && baseNTP_RTP.abssrc === data.source) {
            isShareAudio = true;
          } else {
            baseNTP_RTP = this.normalAudioMap.get(userId >> 10);
          }
          if (baseNTP_RTP) {
            let baseNTP = baseNTP_RTP.ntptime;
            let baseRTP = baseNTP_RTP.rtptime;

            let nextNTP =
              baseNTP +
              ((data.rtpTimestamp - baseRTP) * Math.pow(2, 32)) / 48000;

            if (
              !this.isMultiView &&
              (userId >> 10 == jsMediaEngineVariables.CurrentSSRC >> 10 ||
                isShareAudio)
            ) {
              if (data.source === baseNTP_RTP.abssrc) {
                this.syncSingleView(isShareAudio, nextNTP);
              }
            }
          }
        });
      });
      if (audioReportCount === 0) {
        this.syncSingleView(false, 0);
        this.syncSingleView(true, 0);
      }
    }, 500);
  }
}
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function JsonRPCSignal(joinAudio = false) {
  this.onOffer;

  this.isRtcConnected = false;
  this.isDestroyed = false;
  this.isJoinAudio = joinAudio;
  this.leaveTime = 0;

  var _notifyhandlers;

  this.onopen = function (f) {
    this._onopen = f;
  };

  this.onclose = function (f) {
    this._onclose = f;
  };

  this.onerror = function (f) {
    this._onerror = f;
  };

  this.init = function (uri) {
    this.socket = window.audioBridgeSignal = new WebSocket(uri);
    this._notifyhandlers = {};

    this.socket.addEventListener('open', () => {
      audioBridgeMonitor('WSO');
      if (this.isJoinAudio) {
        this.notify('audiostatus', { status: 1 });
      }
      if (this._onopen) this._onopen();
    });

    this.socket.addEventListener('error', (e) => {
      globalTracingLogger.error('Audio Bridge WebSocket received error', e);
      audioBridgeMonitor('WSE');
      if (this._onerror) {
        this._onerror(
          !this.isDestroyed &&
            !this.isJoinAudio &&
            Date.now() - this.leaveTime >= 15 * 1000
            ? e || true
            : null
        );
      }
      if (!this.isDestroyed) {
        if (this.isJoinAudio || Date.now() - this.leaveTime < 15 * 1000) {
          if (jsMediaEngineVariables.Notify_APPUI) {
            jsMediaEngineVariables.Notify_APPUI(jsEvent.NOTIFY_UI_FAILOVER);
          }
        }
      }
    });

    this.socket.addEventListener('close', (e) => {
      globalTracingLogger.error('Audio Bridge WebSocket close', e);
      audioBridgeMonitor('WSC');
      if (this._onclose) {
        this._onclose(
          !this.isDestroyed &&
            !this.isJoinAudio &&
            Date.now() - this.leaveTime >= 15 * 1000
            ? e || true
            : null
        );
      }
      if (!this.isDestroyed) {
        if (this.isJoinAudio || Date.now() - this.leaveTime < 15 * 1000) {
          if (jsMediaEngineVariables.Notify_APPUI) {
            jsMediaEngineVariables.Notify_APPUI(jsEvent.NOTIFY_UI_FAILOVER);
          }
        }
      }
    });

    this.socket.addEventListener('message', async (event) => {
      if (event.data === 'close') {
        if (jsMediaEngineVariables.Notify_APPUI) {
          jsMediaEngineVariables.Notify_APPUI(jsEvent.NOTIFY_UI_FAILOVER);
        }
        return;
      }
      const resp = JSON.parse(event.data);
      if (resp.method === 'offer') {
        if (this.onOffer) this.onOffer(resp.params);
      } else if (resp.method === 'trickle') {
        if (this.onTrickle)
          this.onTrickle(resp.params.candidate, resp.params.role);
      } else if (resp.method === 'rtcpsr') {
        this.UpdateNTP(
          resp.params.ntptime,
          resp.params.rtptime,
          resp.params.ssrc,
          resp.params.abssrc
        );
      } else {
        const handler = this._notifyhandlers[resp.method];
        if (handler) {
          handler(resp.params);
        }
      }
    });
  };

  this.on_notify = function (method, cb) {
    this._notifyhandlers[method] = cb;
  };

  this.notify = function (method, params) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(
        JSON.stringify({
          method,
          params,
        })
      );
    } else {
      console.error('websocket is not open', this.socket.readyState);
      return;
    }
  };

  this.call = async function (method, params) {
    const id = uuidv4();
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(
        JSON.stringify({
          method,
          params,
          id,
        })
      );
    } else {
      console.error(
        'audio bridge:::: websocket is not open',
        this.socket.readyState
      );
      return;
    }

    return new Promise((resolve, reject) => {
      const handler = (event) => {
        const resp = JSON.parse(event.data);
        if (resp.id === id) {
          if (resp.error) resolve(resp);
          else resolve(resp.result);
          this.socket.removeEventListener('message', handler);
        }
      };
      this.socket.addEventListener('message', handler);
    });
  };

  this.destroy = () => {
    if (this.socket && this.socket.close) {
      this.socket.close();
    }
  };
}
