function Queue() {
  this.a = [];
  this.b = 0;
  this.residue = null;
}
Queue.prototype.getLength = function () {
  return this.a.length - this.b;
};
Queue.prototype.isEmpty = function () {
  return 0 == this.a.length;
};
Queue.prototype.enqueue = function (data) {
  this.a.push(data);
};
Queue.prototype.dequeue = function () {
  if (0 != this.a.length) {
    var c = this.a[this.b];
    2 * ++this.b >= this.a.length &&
      ((this.a = this.a.slice(this.b)), (this.b = 0));
    return c;
  }
  return null;
};
Queue.prototype.peek = function () {
  return 0 < this.a.length ? this.a[this.b] : void 0;
};

Queue.prototype.clear = function () {
  this.a = [];
  this.b = 0;
};
export default Queue;
