import { Util, ReportStatic } from './utils';
function NetStatistic(args) {
  let params = args || {};
  this._samples = [];
  this._interval_id = 0;
  this._lasted_update_time = 0;
  this._lasted_group_time = 0;
  this._enable = false;
  this._interval_time = params.interval || 30000;
  this._customer_callback = params.report_call;
  this._tag = params.tag || 'netreport';
  this._group_interval = params.group_interval || 1000;
  this._enable_advanced = params.advanced || false;
  this._current_count = 0;
  this._qos_report = new ReportStatic({
    tag: 'jitter',
    interval: 30 * 1000,
    reportcallback: this._qos_report_timeout.bind(this),
  });
  this._qos_report_samples = [];
  this._cureen_qos_report = 0;
}

NetStatistic.prototype._qos_report_timeout = function (tag, max, min, count) {
  if (this._customer_callback) {
    let content = `${tag},${max},${min},${count}`;
    this._customer_callback(this._tag + 'TimeOut', content);
  }
};
NetStatistic.prototype._report = function () {
  let date = new Date().getTime();

  let contenct = `${date}-${this._samples.length}-${this._samples}`;
  let qos = `${date}-${this._qos_report_samples.length}-${this._qos_report_samples}`;

  contenct = contenct.replaceAll(',', '|');
  qos = qos.replaceAll(',', '|');

  if (this._customer_callback) {
    this._customer_callback(this._tag, contenct);
    if (this._enable_advanced) this._customer_callback(this._tag + 'QOS', qos);
  } else {
    console.error(`tag:${this._tag},${contenct}`);
  }
};

NetStatistic.prototype._group = function () {
  let now = performance.now();
  if (now >= this._lasted_group_time + 1700) {
    let limit = Math.round((now - this._lasted_group_time) / 1000) - 1;
    for (let i = 0; i < limit; i++) {
      this._samples.push(-1);
    }
  }
  this._lasted_group_time = now;
  this._samples.push(this._current_count);
  this._qos_report_samples.push(this._cureen_qos_report);
  this._cureen_qos_report = 0;
  this._current_count = 0;

  if (now >= this._lasted_update_time + this._interval_time) {
    this._lasted_update_time = now;
    this._report();
    this._samples = [];
    this._qos_report_samples = [];
  }

  // if (now >= this._lasted_group_time + this._group_interval + 1000) {
  //   this._qos_report.timeoutReport(now - this._lasted_group_time, now);
  // }
};
NetStatistic.prototype.start = function () {
  if (!this._enable) {
    this._lasted_update_time = performance.now();
    this._lasted_group_time = this._lasted_update_time;
    this._samples = [];
    this._current_count = 0;

    this._qos_report_samples = [];
    this._cureen_qos_report = 0;

    this._interval_id = setInterval(
      this._group.bind(this),
      this._group_interval
    );
    this._enable = true;
  }
};

NetStatistic.prototype.stop = function () {
  if (this._enable) {
    clearInterval(this._interval_id);
    this._interval_id = 0;
    this._enable = false;
  }
};

NetStatistic.prototype.sample = function (data) {
  if (this._enable) {
    this._current_count++;

    if (this._enable_advanced) {
      if (Util.IsQosReport(data)) {
        this._cureen_qos_report++;
        return;
      }
      if (Util.IsVideoPkg(data)) {
        let time = Util.GetQOSTime(data);
        let now = performance.now();

        if (this._lasted_qos_ts) {
          let diff = now - this._lasted_sys_ts;
          let qos_dif = time - this._lasted_qos_ts;
          let jitter = diff - qos_dif;
          if (jitter > 30) {
            this._qos_report.timeoutReport(jitter, now);
          }
        }
        this._lasted_qos_ts = time;
        this._lasted_sys_ts = now;
        this._lasted_data = data;
      }
    }
  }
};

export { NetStatistic };
