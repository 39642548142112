import * as jsMediaEngine from './JsMediaEngine';
import jsMediaEngineVariables from '../inside/JsMediaEngine_Variables';
import Zoom_Monitor from '../inside/Monitor';
import { CURRENT_SSRC_TIME } from '../common/jsEvent';
let zoomAudioWorkletNode = null;

if (typeof AudioWorklet === 'function') {
  class ZoomAudioWorkletNode extends AudioWorkletNode {
    constructor(context, module, options) {
      super(context, module, options);
      this.port.onmessage = this.handleMessage.bind(this);
    }

    handleMessage(event) {
      var data = event.data;
      switch (data.status) {
        case 'delay':
          {
            if (jsMediaEngineVariables.localAudioDecMGR) {
              var f = jsMediaEngineVariables.localAudioDecMGR.map.get(0);
              if (f) {
                f.postMessage({ command: 'delay' });
              }
            }
          }
          break;
        case CURRENT_SSRC_TIME:
          {
            if (data.at) {
              jsMediaEngineVariables.CurrentSSRCTime = data.at;
            }
            if (
              data.st &&
              jsMediaEngineVariables.mediaSDKHandle.SharingRenderObj
            ) {
              jsMediaEngineVariables.mediaSDKHandle.SharingRenderObj.SetcATimeStamp(
                data.st
              );
            }
          }
          break;
        case 'AUDIO_MONITOR_LOG':
          {
            jsMediaEngine.addAudioMonitorLog.push({
              log: data.data,
              isSend: true, //same as audio decode worker
            });
          }
          break;
        case 'MONITOR_LOG':
          {
            Zoom_Monitor.add_monitor(data.data);
          }
          break;
        case 'UPDATE_MULTIVIEW_TIME':
          {
            if (jsMediaEngineVariables.localVideoDecMGR) {
              var f = jsMediaEngineVariables.localVideoDecMGR.map.get(0);
              if (f) {
                f.postMessage({
                  command: 'audioDecodeTime',
                  data: data.data,
                  status: 1,
                });
              }
            }
          }
          break;
        case 'WASM_INIT_SUCCESS':
          {
            Zoom_Monitor.add_monitor('DIWL' + data.data.audio_handle);
            jsMediaEngineVariables.workletWasmInitSuccess = true;
            while (!jsMediaEngineVariables.interpretationMessage.isEmpty()) {
              let param =
                jsMediaEngineVariables.interpretationMessage.dequeue();
              this.postCMD(param.command, param);
            }
          }
          break;
        // case 'fileData':
        //     let { fileName} = data;

        //     const blob = new Blob([data.data.buffer], {
        //     type: 'application/octet-stream',
        //     });
        //     const objUrl = URL.createObjectURL(blob);
        //     const aTag = document.createElement('a');
        //     aTag.href = objUrl;
        //     aTag.download = fileName;
        //     aTag.click();
        //     URL.revokeObjectURL(objUrl);
        //     break;
      }
    }

    postData(status, data) {
      this.port.postMessage(
        {
          status: status,
          data: data,
        },
        [data.data.buffer]
      );
    }

    postCMD(status, data) {
      this.port.postMessage({
        status: status,
        data: data,
      });
    }
  }

  zoomAudioWorkletNode = ZoomAudioWorkletNode;
}

export default zoomAudioWorkletNode;
