/**
 * This library must be safe for worker to use,
 * cause some script in worker also depend on this library
 */
// 2020.12.23 remove stack info, because it never be used and conflict with 3rd library (eg:console.js)
let id = 1;
let isEnableLog =
  typeof localStorage !== 'object' ? false : localStorage['__islogdebug'];
// ("if you want to get log message, set localStorage['__islogdebug'] = true");

var facroty = function (groupname, isEnableLogWhenLocalStorageCannotAccess) {
  if (isEnableLogWhenLocalStorageCannotAccess === true) {
    isEnableLog = true;
  }
  function generateArguments() {
    let list = [];

    list.push(...arguments);

    id++;
    return list;
  }

  var fn = function () {
    if (isEnableLog) {
      console.log(groupname, ...generateArguments(arguments));
    }
  };

  let list = ['log', 'info', 'warn', 'error'];

  list.forEach((k) => {
    fn[k] = function () {
      if (isEnableLog) {
        console[k](groupname, ...generateArguments(arguments));
      }
    };
  });

  fn.isEnable = function () {
    return isEnableLog;
  };

  return fn;
};

facroty.isEnable = function () {
  return isEnableLog;
};

export default facroty;
