//web work
//success
export const Audio_Dec_WASM_OK = 1;
export const Audio_Dec_Handle_OK = 2;
export const Audio_Dec_WebSocket_OK = 3;
export const Audio_Enc_WASM_OK = 4;
export const Audio_Enc_Handle_OK = 5;
export const Video_Dec_WASM_OK = 7;
export const Video_Dec_Handle_OK = 8;
export const Video_Dec_WebSocket_OK = 9;
export const Video_Enc_WASM_OK = 10;
export const Video_Enc_Handle_OK = 11;
export const Sharing_Dec_WASM_OK = 12;
export const Sharing_Dec_PICTURE = 13;
export const AUDIO_DELAY = 14;
export const Sharing_Dec_WebSocket_OK = 15;
export const Sharing_Handle_OK = 16;
export const Sharing_Data = 17;
export const DECODE_MESSAGE = 18;
export const Video_Capture_Tick = 20;
export const MONITOR_MESSAGE = 21;
export const SHARING_DECODE_MONITOR_MESSAGE = 3021;
export const WORKER_MAIN_VIDEO_ENCODE_RINGBUFFER_TICK = 22;
export const WORKER_MAIN_AUDIO_ENCODE_RINGBUFFER_TICK = 23;
export const WORKER_MAIN_VIDEO_DECODE_RINGBUFFER_TICK = 24;
export const Audio_Encode_Preview_OK = 26;
export const Video_Encode_Preview_OK = 27;

export const QOS_MONITORING = 'QosMonitoring';
export const QOS_DEFAULT_POLLING_INTERVAL = 1000;
export const VIDEO_MONITOR_LOG_SECOENDS = 5;
export const DOWNLOAD_WASM_FROM_MAIN_THREAD = 30;
export const APP_TROUBLESHOOTING_INFO = 31;
export const DELTA_D = 32;
export const DELTA_C = 33;
export const DELTA_S = 34;
export const WCL_TROUBLESHOOTING_INFO = 35;
export const SHARING_DATA_VIDEO_MODE = 36;
export const MOUSE_DATA_VIDEO_MODE = 37;
export const SHARING_DECODE_MESSAGE = 38;
export const VIDEO_ENCODED_DATA = 39;
export const VIDEO_DROP_RATIO_FROM_WCL = 40;
export const VIDEO_DATA_DROP_RATIO = 41;
export const AUDIO_ENCODED_DATA = 42; // push encoded audio data from worker to main thread
export const THREAD_STATE_IDLE = 43;
export const THREAD_STATE_CREATING = 44;
export const THREAD_STATE_CREATED = 45;
export const THREAD_ENCODE_BUFFER = 46;
export const WASMPTR = 47;
export const AUDIO_MONITOR_LOG = 48;
export const WhiteBoard_Video_Capture_Tick = 60;
// A message from the worker that should be logged.
export const GLOBAL_TRACING_LOG = 61;
//failed
export const Audio_Dec_WASM_FAILED = -1;
export const Audio_Dec_Handle_FAILED = -2;
export const Audio_Dec_WebSocket_FAILED = -3;
export const Audio_Enc_WASM_FAILED = -4;
export const Audio_Enc_Handle_FAILED = -5;
export const Video_Dec_WASM_FAILED = -7;
export const Video_Dec_Handle_FAILED = -8;
export const Video_Dec_WebSocket_FAILED = -9;
export const Video_Enc_WASM_FAILED = -10;
export const Video_Enc_Handle_FAILED = -11;
export const Sharing_Dec_WASM_FAILED = -12;
export const Audio_Mute = -13;
export const Sharing_Handle_FAILED = -14;
export const Sharing_Dec_WebSocket_FAILED = -15;
export const AUDIO_CLIPPING = -23;
export const MULTIVIEW_WEBGL_CONTEXT_LOST = -26;
export const WEBGL_CONTEXT_CREATE_FAILED = -27;
export const MEDIA_VIDEO_REPORT_DROP_RATIO = 100;

export const MEDIA_S2C_KEEPALIVE = 0;
export const MEDIA_AUDIO_DATA = 1;
export const MEDIA_AUDIO_RTCP = 6;
export const MEDIA_VIDEO_DATA = 2;
export const MEDIA_VIDEO_RTCP = 7;
export const MEDIA_NTP_UPDATE = 9;
export const VIDEO_CAPTURER_RESOLUTION_360P = 10;
export const VIDEO_CAPTURER_RESOLUTION_720P = 11;
export const CURRENT_VIDEO_RESOLUTION = 50;
export const VIDEO_RENDER_MONITOR_LOG = 51;
export const Sharing_Width_And_Height_Info = 52;
export const SHARING_RENDER_MONITOR_LOG = 53;
export const SHARING_GET_IMAGE_DATA_WRONG = 54;
export const AES_GCM_IV_TO_WASM = 55;
export const AES_GCM_IV_CALLBACK_FROM_WASM = 56;
export const CURRENT_SSRC_TIME = 57;

export const CURRENT_DECODE_VIDEO_QUALITY = 66.5;
export const CURRENT_DECODE_VIDEO_FPS = 66.6;
export const CURRENT_CAPTURE_VIDEO_WIDTH_HEIGHT = 67;
export const CURRENT_DESKTOP_SHARING_WIDTH_HEIGHT = 68;
export const SHARING_FIRST_DECODE_FRAME_RECEIVED = 69;
export const VIDEO_CAPTURER_RESOLUTION_CHANGE = 71;

export const VIDEO_CAPTURE_FRAME_COUNT_STATISTIC = 72;
export const SHARING_CAPTURE_FRAME_COUNT_STATISTIC = 73;

export const UNSUPPORTED_SHARING_FORMAT = 75;
export const UNSUPPORTED_VIDEO_FORMAT = 76;

export const FIRST_SHARING_FRAME_FOR_MOBILE = 78;

export const VB_PTHREAD_MODEL_ALL_READY = 95;

export const UPDATE_ENCRYPTION_GCM_MODEL_KEY = 102;

export const CONNECT_WEBTRANSPORT_OK = 105;
export const CONNECT_WEBTRANSPORT_CLOSE = 106;
export const CURRENT_MEDIA_DATA_TRANSPORT_TYPE = 107;
export const CONNECT_WEBSOCKET_CLOSE = 108;
export const CURRENT_ENCODED_TYPE = 109;
export const WHITEBOARD_WORKER_MESSAGE = 120;
export const INTERPRETATION_ENABLE = 0;
export const INTERPRETATION_SET_LANG = 1;
export const INTERPRETATION_MUTE = 2;
export const INTERPRETATION_SET_INTERPRETER = 3;

/**
 * webclient set 60 seconds, SDK set 65 seconds
 */
export const serverHeartbeatMaxTimeoutSeconds = 65;

//video render Mode
export const RQUEST_ANIMATION_MODE = 0;
export const SET_INTERVAL_MODE = 1;

//video Mode
export const VIDEO_INVALID = -1;
export const VIDEO_RGBA = 0;
export const VIDEO_I420 = 1;
export const VIDEO_NV12 = 2;
export const VIDEO_BGRA = 3;

// audio worker & audio worklet message channel
export const EVENT_ROLLBACK_BUFFER = 0;
export const EVENT_NEEDMORE_DATA = 1;
export const EVENT_CAPTURE_DATA = 2;
export const EVENT_CACHE_SIZE = 3;

//webcodec parameters
export const WEBCODEC_ENCODE_OPEN_FLAG = true;

// export const GALLERY_VIEW_MAX_NUMB_THREADS = 28;
// export const WORKER_STATUS = {
//   startAsking: 'startAsking',
//   finishAsking: 'finishAsking',
//   startExiting: 'startExiting',
//   finishExiting: 'finishExiting',
// };

export const VB_SIGNAL_TYPE_SET_BG = 0;
export const VB_SIGNAL_TYPE_SET_BLUR = 1;

export const QosSession = {
  SESSION_TYPE_CONF: 0,
  SESSION_TYPE_AUDIO: 1,
  SESSION_TYPE_DESKSHARE: 2,
  SESSION_TYPE_VIDEO: 3,
  SESSION_TYPE_CHAT: 4,
  SESSION_TYPE_TELEPHONE: 5,
  SESSION_TYPE_ZC_PING: 6,
  SESSION_TYPE_TOTAL_CNT: 7 /** the auto end of the enum, the count of total session types*/,
};

export const QosConnectType = {
  CONNECT_TYPE_UDP: 0,
  CONNECT_TYPE_TCP: 1,
};

//VIDEO CAPTURE
export const MAX_VIDEO_CAPTURE_FPS = 30;
export const MIN_VIDEO_CAPTURE_FPS = 1;
export const VIDEO_CAPTURE_FPS = 24;
export const VIDEO_CAPTURE_20FPS = 20;
export const DOWN_VIDEO_CAPTURE_FPS = 15;
export const LOWER_VIDEO_CAPTURE_FPS = 10;
export const VIDEO_DATA_MAX_SIZE = 1920 * 1080 * 4;

//SHARING MODE

export const SHARING_NULL = 0; //sharing undefined
export const SHARING_NORMAL = 1;
export const SHARING_VIDEO_MODE = 2;
export const SHARING_VIDEO_MODE_CAPTURED_FPS = 15;
export const SHARING_NORMAL_MODE_CAPTURED_FPS = 5;
export const VIDEO_RINGBUF_PKG_NUM = 400;
//end sharing mode

export const NETWORK_QUALITY_CHANGE = 'NETWORK_QUALITY_CHANGE';

export const ADDITIONNAL_MULTITHREAD_NUMBER_ENCODE_FOR_360P = 3;
export const ADDITIONNAL_MULTITHREAD_NUMBER_ENCODE_FOR_720P = 7;

//Platform TYPE
export const WCL_PLATFORM_TYPE = {
  DESKTOP: 0,
  MOBILE: 1,
  ANDROID: 2,
  IPHONE: 3,
};

export const RENDER_MODE_RAF = 0;
export const RENDER_MODE_INTERVAL = 1;
