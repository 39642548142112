import FileLogger, { FileWriter } from 'disk-file-writer';
import { WORKER_TYPE, WorkerLogFileNameMap } from '../common/enums/CommonEnums';
import JsMediaEngine_Variables from './JsMediaEngine_Variables';
import { pushMessageToWorker } from '../lib/JsMediaEngine';

const WorkerInstanceMap = {
  [WORKER_TYPE.AUDIO_ENCODE]: 'audioEncodeInitInstance',
  [WORKER_TYPE.AUDIO_DECODE]: 'audioDecInitInstance',
  [WORKER_TYPE.VIDEO_ENCODE]: 'videoInitInstance',
  [WORKER_TYPE.VIDEO_DECODE]: 'videoDecInitInstance',
  [WORKER_TYPE.SHARING_ENCODE]: 'sharingEncInitInstance',
  [WORKER_TYPE.SHARING_DECODE]: 'sharingDecInitInstance',
};

async function getFileWriter() {
  const fileWriter = new FileWriter({
    maxDaysToKeepFile: 1,
    fileSizeQuota: 1024 * 1024 * 10, // 10MB for each log file
  });
  return new Promise((resolve) => {
    fileWriter.on('initOK', () => {
      resolve(fileWriter);
    });
  });
}

export class LocalLog {
  constructor() {
    this.logWriterMap = window.logWriterMap = new Map();
    this.inited = false;
  }

  async init() {
    if (this.inited) {
      return;
    }
    this.inited = true;
    Object.values(WORKER_TYPE).forEach(async (workerType) => {
      const writer = await getFileWriter();
      // writer.clearOPFSFiles();
      this.logWriterMap.set(workerType, writer);
      await JsMediaEngine_Variables[
        WorkerInstanceMap[workerType]
      ].waitforInitSuccess();
      const port = writer.createMessagePort();
      pushMessageToWorker(workerType, port, 'local_log_port', true, true);
      writer.startWriteNormalLogFile({
        filenamePrefix: WorkerLogFileNameMap[workerType],
        filenameExtension: '.log',
        timestamp: false,
        newLine: false,
      });
      writer.on('startWriteNormalLogFileReady', () => {
        pushMessageToWorker(workerType, null, 'local_log_ready', false, true);
      });
    });
  }

  saveLogFileByWorkerType(workerType) {
    const writer = this.logWriterMap.get(workerType);
    if (writer) {
      writer.saveLog();
    }
  }

  saveAllLogFiles() {
    FileLogger.selectSaveDir().then(() => {
      Object.values(WORKER_TYPE).forEach((workerType) => {
        FileLogger.saveLog({
          filenamePrefix: WorkerLogFileNameMap[workerType],
          filenameExtension: '.log',
        });
      });
    });
  }
}
