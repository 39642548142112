import * as jsEvent from '../common/jsEvent';
import * as consts from '../worker/common/consts';
import JsMediaSDK_WaterMarkRGBA from './JsMediaSDK_WaterMark';
import H264bsdCanvas from '../common/WebGLCanvas';
import { VideoQueueMGR } from '../inside/JsMediaBuffer';
import {
  YuvWrap,
  multiview_webgl_monitor,
  create_webgl_context_failed_monitor,
  globaltracing_error,
} from '../worker/common/common';

/**
 * @description get target ratio cropping params
 * @param {*} drawRatio cropping ratio (16:9 default)
 */
export const get16V9CroppingParams = (
  width,
  height,
  left = 0,
  top = 0,
  drawRatio = 16 / 9
) => {
  if (!width || !height) {
    return null;
  }
  const originLeft = left || 0;
  const originTop = top || 0;
  if (width / height > drawRatio) {
    /** data is widther than render area */
    const realDrawDataWidth = height * drawRatio;
    return {
      width: realDrawDataWidth,
      height: height,
      left: Math.round((width - realDrawDataWidth) / 2) + originLeft,
      top: 0 + originTop,
    };
  } else {
    /** data is heighter than render area */
    const realDrawDataHeight = width / drawRatio;
    return {
      width: width,
      height: realDrawDataHeight,
      left: 0 + originLeft,
      top: Math.round((height - realDrawDataHeight) / 2) + originTop,
    };
  }
};

function VideoRender(params) {
  this.Notify_APPUI = params.Notify_APPUI;
  this.isSupportOffscreenCanvas = params.isSupportOffscreenCanvas;
  this.jsMediaEngine = params.jsMediaEngine;
  this.threadNumbs = params.videodecodethreadnumb;
  this.globalTracingLogger = params.globalTracingLogger;
  this.waterMarkCanvas = null;
  this.videoRenderLevel = 10;
  this.videoRenderLevelCount = 1;
  this.renderPeriodTotal = 100;
  this.renderPeriodCount = 0;
  this.renderPeriodTotalFps = 0;
  this.renderPeriodFpsCount = 0;
  this.lastRenderAudioTimeStamp = 0;
  this.videoBufEmptyCount = 0;
  this.videoBufCount = 0;
  this.videoRenderMaxLevel = 15;
  this.timestamp = 0;
  this.audioPlayTime = 0;
  this.videoTimestamp = 0;
  this.videoWaterMarkName = '';
  this.isCreateVideoWaterMark = false;
  /** if makes watermark repeated */
  this.isWaterMarkRepeatedEnable = false;
  this.waterMarkOpacity = 0.15;
  this.currentactive = 0;
  this.currentVideoHeight = 0;
  this.currentVideoWidth = 0;
  this.videoRenderArray = [];
  this.WaterMarkRGBA = new JsMediaSDK_WaterMarkRGBA();
  this.vMonitorCount = 0;
  this.videoQueue = new VideoQueueMGR();
  this.timestart = 0;
  this.rendingFlag = true;
  this.rAFID = 0;
  this.rAFIDBack = null;
  this.prevRequestAnimationTimestap = 0;
  this.retryRequestAnimationStep = 2;
  this.renderMGR = null;
  this.ssrcDispalyMap = new Map();
  this.ssrcHaddataMap = new Map();
  this.selfvideossrc = 0;
  this.localVideoPtr = 0;
  this.sabBuffer = 0;
  this.localvideossrc = 0;
  this.encodedVideoSharedArrayBufferUint8Array = null;
  this.encodedVideoSharedArrayBufferUint16Array = null;
  this.encodedVideoSharedArrayWasmMemory = null;
  this.startCaptureVideo = false;
  this.clearColor = new Uint8Array(4);
  this.videorendercanvas = null;
  this.keyrender = null;
  this.renderIndex = 0;
  /**
   * 0 => requestAnimation mode
   * 1 => setInterval mode
   */
  this.renderMode = 0;
  this.intervalHandle;
  this.croppingParams = {
    top: 0,
    left: 0,
    height: 1,
    width: 1,
  };
  this.coordinate = {
    x: 0,
    y: 0,
    width: 640,
    height: 360,
  };
  this.isSupportVideoTrackReader = false;
  this.bWebglContextLostProtectingMap = new Map();

  this.ismirror = false;

  this.lfTimeStamp = 0;
  this.activeFps = 0;
  this.lastActiveSSRC = 0;

  if (process.env.NODE_ENV === 'development') {
    let globalScope = typeof window === 'undefined' ? self : window;
    globalScope.videoRenderOBJ = this;
  }

  /** disable originalRatio or not */
  this.disableOriginalRatio = false;

  /** if enable replace canvas when webgl context lost */
  this.enableReplaceCanvasWhenContextLost =
    params.enableReplaceCanvasWhenContextLost;
}

VideoRender.prototype.clearUseridRender = function (data, RGBA) {
  let obseleteData = data[0];
  let display = obseleteData.display;
  if (!display) {
    return;
  }
  display.SetWaterMarkFlag(0);
  if (this.renderMode) {
    display.clearCanvas(RGBA);
  } else {
    let obseletessrc = obseleteData.ssrc;
    if (!display || !RGBA) {
      return;
    }

    this.croppingParams.top = 0;
    this.croppingParams.left = 0;
    this.croppingParams.height = 1;
    this.croppingParams.width = 1;
    this.clearColor[0] = RGBA.R * 255;
    this.clearColor[1] = RGBA.G * 255;
    this.clearColor[2] = RGBA.B * 255;
    this.clearColor[3] = RGBA.A * 255;

    display.UpdateSelfVideoTextures(1, 1, this.clearColor, this.croppingParams);

    let that = this;
    this.ssrcDispalyMap.forEach(function (value, key) {
      let isselfvideo;
      if (
        that.Get_Logical_SSrc(key) == that.Get_Logical_SSrc(that.selfvideossrc)
      ) {
        isselfvideo = true;
      }

      let isCliearVideo;
      if (that.Get_Logical_SSrc(key) == that.Get_Logical_SSrc(obseletessrc)) {
        isCliearVideo = true;
      }
      value.forEach((frameWrapper) => {
        if (!frameWrapper.haddata && !isCliearVideo) {
          return;
        }
        let display = frameWrapper.display;
        that.coordinate.x = frameWrapper.x;
        that.coordinate.y = frameWrapper.y;
        that.coordinate.width = frameWrapper.width;
        that.coordinate.height = frameWrapper.height;

        if (display) {
          if (isCliearVideo) {
            display.DrawSelfVideo(that.coordinate, true);
          } else {
            if (display.GetVideoMode() != consts.VIDEO_INVALID) {
              if (
                [consts.VIDEO_RGBA, consts.VIDEO_BGRA].indexOf(
                  display.GetVideoMode()
                ) == -1 &&
                isselfvideo
              ) {
                display.DrawSelfVideo(that.coordinate, false, that.ismirror);
              } else {
                if (isselfvideo) {
                  display.DrawRemoteVideo(that.coordinate, that.ismirror);
                } else {
                  display.DrawRemoteVideo(that.coordinate);
                }
              }
            }
          }
        }
      });
    });
  }
};

VideoRender.prototype.Set_Render_Array_rAF = function (videoRenderArray) {
  //We will cut down cost Next Phase
  //@(seth.wang)
  this.videoRenderArray = videoRenderArray;
  this.ssrcDispalyMap.clear();
  this.ssrcHaddataMap.clear();
  if (this.videoRenderArray.length > 0) {
    for (let i = 0; i < this.videoRenderArray.length; i++) {
      let displayarray = this.ssrcDispalyMap.get(
        this.Get_Logical_SSrc(this.videoRenderArray[i].ssrc)
      );
      if (displayarray) {
        displayarray.push(this.videoRenderArray[i]);
      } else {
        displayarray = [];
        displayarray.push(this.videoRenderArray[i]);
        this.ssrcDispalyMap.set(
          this.Get_Logical_SSrc(this.videoRenderArray[i].ssrc),
          displayarray
        );
        this.ssrcHaddataMap.set(
          this.Get_Logical_SSrc(this.videoRenderArray[i].ssrc),
          false
        );
      }
    }
  }
};

VideoRender.prototype.Set_Render_Array = function (
  videoRenderArray,
  enableMultiDecodeVideoWithoutSAB
) {
  if (this.renderMode) {
    this.Set_Render_Array_IntervalMode(
      videoRenderArray,
      enableMultiDecodeVideoWithoutSAB
    );
  } else {
    this.Set_Render_Array_rAF(videoRenderArray);
  }
};

VideoRender.prototype.ClearQueue = function () {
  if (this.videoQueue) {
    this.videoQueue.ClearQueue();
  }
  this.currentVideoHeight = 0;
  this.currentVideoWidth = 0;
};

VideoRender.prototype.Put_Video_Into_Buffer = function (message) {
  if (this.videoQueue) {
    var ssrcqueue_ = this.videoQueue.GetQueue(message.ssrc);
    if (!ssrcqueue_) {
      ssrcqueue_ = this.videoQueue.AddQueue(message.ssrc);
    }
    ssrcqueue_.enqueue(message);
    if (this.videoQueue && this.videoQueue.ssrcInfo) {
      var ssrc_node_part = message.ssrc >> 10;
      this.videoQueue.ssrcInfo.UpdateSSRCInfo(ssrc_node_part, message.ntptime);
    }
    var videoqueuelength = this.videoQueue.GetQueueLength(message.ssrc);
    var diff = videoqueuelength - 10;
    while (diff >= 0) {
      diff--;
    }
  }
};

VideoRender.prototype.Update_RemoteVideo_Texture = function (
  message,
  newmode = false,
  remotessrc = 0
) {
  this.Check_Request_Animation();
  if (!newmode) {
    if (message && message.ssrc) {
      let ssrc = this.Get_Logical_SSrc(message.ssrc);
      let ssrcRenderArray = this.ssrcDispalyMap.get(ssrc);
      if (!ssrcRenderArray) {
        if (message.yuvdataptr) {
          Module._free(message.yuvdataptr);
        }
        return;
      }
      let renderFrame;
      let isolddata = false;
      let that = this;
      this.ssrcHaddataMap.set(ssrc, true);
      let video_buf = message;

      this.croppingParams.top = video_buf.r_y;
      this.croppingParams.left = video_buf.r_x;
      this.croppingParams.height = video_buf.r_h;
      this.croppingParams.width = video_buf.r_w;

      renderFrame = video_buf;
      if (renderFrame.enableMultiDecodeVideoWithoutSAB) {
        renderFrame.yuvdata = renderFrame.data;
      } else {
        renderFrame.yuvdata = Module.HEAPU8.subarray(
          renderFrame.yuvdataptr,
          renderFrame.yuvdataptr + renderFrame.yuvlength
        );
      }
      //end video frame
      if (
        !(
          !renderFrame.yuvdata.length ||
          renderFrame.yuvdata.length !=
            (renderFrame.width * renderFrame.height * 3) / 2 ||
          this.croppingParams.top < 0 ||
          this.croppingParams.left < 0 ||
          this.croppingParams.left + this.croppingParams.width >
            renderFrame.width ||
          this.croppingParams.top + this.croppingParams.height >
            renderFrame.height
        )
      ) {
        if (ssrcRenderArray) {
          ssrcRenderArray.forEach((frameWrapper) => {
            frameWrapper.haddata = true;
            let display = frameWrapper.display;
            that.coordinate.x = frameWrapper.x;
            that.coordinate.y = frameWrapper.y;
            that.coordinate.width = frameWrapper.width;
            that.coordinate.height = frameWrapper.height;

            if (display) {
              display.SetVideoMode(consts.VIDEO_I420);
              if (
                this.Should_Update_Watermark(
                  display,
                  frameWrapper.width,
                  frameWrapper.height,
                  frameWrapper.width,
                  frameWrapper.height
                )
              ) {
                this.Update_Display_Watermark(
                  display,
                  frameWrapper.width,
                  frameWrapper.height,
                  frameWrapper.width,
                  frameWrapper.height
                );
              }
              if (renderFrame) {
                display.UpdateRemoteVideoTextures(
                  renderFrame.width,
                  renderFrame.height,
                  that.croppingParams,
                  renderFrame.yuvdata,
                  renderFrame.rotation,
                  renderFrame.yuv_limited,
                  that.coordinate,
                  isolddata
                );
              }
            }
          });
        }
      }
    }

    if (message.yuvdataptr) {
      Module._free(message.yuvdataptr);
    }
  } else {
    //start
    if (message && remotessrc) {
      let ssrc = this.Get_Logical_SSrc(remotessrc);
      let ssrcRenderArray = this.ssrcDispalyMap.get(ssrc);
      if (!ssrcRenderArray) {
        return;
      }
      let renderFrame;
      let isolddata = false;
      let that = this;

      this.ssrcHaddataMap.set(ssrc, true);
      let video_buf = message;

      this.croppingParams.top = message.visibleRect ? 0 : message.cropTop;
      this.croppingParams.left = message.visibleRect ? 0 : message.cropLeft;
      this.croppingParams.height = message.visibleRect
        ? message.visibleRect.height
        : message.cropHeight;
      this.croppingParams.width = message.visibleRect
        ? message.visibleRect.width
        : message.cropWidth;

      if (true) {
        if (ssrcRenderArray) {
          ssrcRenderArray.forEach((frameWrapper) => {
            frameWrapper.haddata = true;
            let display = frameWrapper.display;

            that.coordinate.x = frameWrapper.x;
            that.coordinate.y = frameWrapper.y;
            that.coordinate.width = frameWrapper.width;
            that.coordinate.height = frameWrapper.height;

            if (display) {
              display.SetVideoMode(consts.VIDEO_RGBA);
              if (
                that.Should_Update_Watermark(
                  display,
                  frameWrapper.width,
                  frameWrapper.height,
                  frameWrapper.width,
                  frameWrapper.height
                )
              ) {
                that.Update_Display_Watermark(
                  display,
                  frameWrapper.width,
                  frameWrapper.height,
                  frameWrapper.width,
                  frameWrapper.height
                );
              }
              if (message) {
                display.UpdateRemoteVideoTexturesImageBitmap(
                  that.croppingParams.width,
                  that.croppingParams.height,
                  message,
                  that.croppingParams,
                  message.rotation
                );
              }
            }
          });
        }
      }
    }
    //end
  }
};

/** set croppong mode use original ratio or not */
VideoRender.prototype.Set_Cropping_Mode = function (
  disableOriginalRatio = false
) {
  this.disableOriginalRatio = !!disableOriginalRatio;
};

/** if not use original ratio, need to reset cropping params */
VideoRender.prototype.Update_Cropping_Params = function () {
  if (this.disableOriginalRatio) {
    const croppingParams = get16V9CroppingParams(
      this.croppingParams.width,
      this.croppingParams.height,
      this.croppingParams.left,
      this.croppingParams.top
    );
    if (croppingParams) {
      this.croppingParams = {
        ...this.croppingParams,
        ...croppingParams,
      };
    }
  }
};

VideoRender.prototype.Update_SelfVideo_Texture = function (
  width,
  height,
  data,
  ssrc,
  mode = consts.VIDEO_RGBA,
  cropping = 0
) {
  if (!cropping) {
    this.croppingParams.top = 0;
    this.croppingParams.left = 0;
    this.croppingParams.height = height;
    this.croppingParams.width = width;
  } else {
    this.croppingParams.top = cropping.top;
    this.croppingParams.left = cropping.left;
    this.croppingParams.width = cropping.width;
    this.croppingParams.height = cropping.height;
  }
  if (this.renderMode) {
    let index = 0;
    for (; index < this.videoRenderArray.length; index++) {
      if (
        this.Get_Logical_SSrc(this.videoRenderArray[index].ssrc) ==
        this.Get_Logical_SSrc(ssrc)
      ) {
        this.coordinate.x = this.videoRenderArray[index].x;
        this.coordinate.y = this.videoRenderArray[index].y;
        this.coordinate.width = this.videoRenderArray[index].width;
        this.coordinate.height = this.videoRenderArray[index].height;
        this.Update_Cropping_Params();

        this.videoRenderArray[index].display.SetVideoMode(mode);
        const {
          display,
          width: displayWidth,
          height: displayHeight,
        } = this.videoRenderArray[index];
        if (
          this.Should_Update_Watermark(
            display,
            displayWidth,
            displayHeight,
            displayWidth,
            displayHeight
          )
        ) {
          this.Update_Display_Watermark(
            display,
            displayWidth,
            displayHeight,
            displayWidth,
            displayHeight
          );
        }
        if ([consts.VIDEO_RGBA, consts.VIDEO_BGRA].indexOf(mode) !== -1) {
          this.videoRenderArray[index].display.UpdateSelfVideoTextures(
            width,
            height,
            data,
            this.croppingParams
          );
          if (this.videoRenderArray[index].enableMultiDecodeVideoWithoutSAB) {
            this.videoRenderArray[index].firstFrame = true;
          } else {
            this.videoRenderArray[index].display.DrawSelfVideo(
              this.coordinate,
              false,
              this.ismirror
            );
          }
        } else {
          this.videoRenderArray[index].display.UpdateRemoteVideoTextures(
            width,
            height,
            this.croppingParams,
            data,
            0,
            0,
            this.coordinate,
            false
          );
          if (this.videoRenderArray[index].enableMultiDecodeVideoWithoutSAB) {
            this.videoRenderArray[index].firstFrame = true;
          } else {
            this.videoRenderArray[index].display.DrawRemoteVideo(
              this.coordinate,
              this.ismirror
            );
          }
        }
        // break;  //interval mode support draw self as active
      }
    }
  } else {
    if (data && ssrc) {
      let ssrcRenderArray = this.ssrcDispalyMap.get(ssrc);
      this.ssrcHaddataMap.set(ssrc, true);
      let that = this;
      if (ssrcRenderArray) {
        ssrcRenderArray.forEach((frameWrapper) => {
          frameWrapper.haddata = true;
          that.coordinate.x = frameWrapper.x;
          that.coordinate.y = frameWrapper.y;
          that.coordinate.width = frameWrapper.width;
          that.coordinate.height = frameWrapper.height;
          that.Update_Cropping_Params();
          let display = frameWrapper.display;
          if (display) {
            display.SetVideoMode(mode);
            if (
              that.Should_Update_Watermark(
                display,
                frameWrapper.width,
                frameWrapper.height,
                frameWrapper.width,
                frameWrapper.height
              )
            ) {
              that.Update_Display_Watermark(
                display,
                frameWrapper.width,
                frameWrapper.height,
                frameWrapper.width,
                frameWrapper.height
              );
            }
            if ([consts.VIDEO_RGBA, consts.VIDEO_BGRA].indexOf(mode) !== -1) {
              display.UpdateSelfVideoTextures(
                width,
                height,
                data,
                that.croppingParams
              );
            } else {
              display.UpdateRemoteVideoTextures(
                width,
                height,
                that.croppingParams,
                data,
                0,
                true,
                that.coordinate,
                false
              );
            }
          }
        });
      }
    }
  }
  return;
};

VideoRender.prototype.Draw_Send_Video_Img = function (
  data,
  width,
  height,
  ssrc,
  mode = consts.VIDEO_RGBA,
  cropping = 0
) {
  this.Update_SelfVideo_Texture(width, height, data, ssrc, mode, cropping);
};

VideoRender.prototype.setMirrorMyVideoOption = function (ismirror) {
  this.ismirror = ismirror;
};

VideoRender.prototype.Start_Draw = function (mode, interval_time = 10) {
  //mode 1 represent interval mode;
  //mode 0 represent requestAnimation Mode;
  this.rendingFlag = true;
  this.renderMode = mode;
  if (!mode) {
    set_VideoRenderHandle(this);
    return this.Start_Request_Animation_Frame();
  } else {
    if (interval_time) {
      this.intervalHandle = setInterval(
        this.JsMediaSDK_VideoRender_interval.bind(this),
        interval_time
      );
      return this.intervalHandle;
    }
  }
  return null;
};

VideoRender.prototype.Stop_Draw = function () {
  this.rendingFlag = false;
  if (!this.renderMode) {
    this.Stop_Request_Animation_Frame();
  } else {
    clearInterval(this.intervalHandle);
  }
};

VideoRender.prototype.webGLContextLostProtect = function (canvas, canvasId) {
  if (this.bWebglContextLostProtectingMap.get(canvas)) {
    return;
  }

  this.bWebglContextLostProtectingMap.set(canvas, canvasId);
  if (this.renderMode) return;
  canvas.addEventListener(
    'webglcontextlost',
    (event) => {
      console.log('webglcontextlost', canvasId);
      if (this.rAFID) {
        this.Stop_Request_Animation_Frame();
      }
      if (this.enableReplaceCanvasWhenContextLost) {
        if (canvas.contextLostTimer) {
          clearTimeout(canvas.contextLostTimer);
          canvas.contextLostTimer = null;
        }
        canvas.contextLostTimer = setTimeout(() => {
          canvas.contextLostTimer = null;
          multiview_webgl_monitor(canvasId, true);
        }, 3000);
      } else {
        multiview_webgl_monitor(canvasId);
      }
      event.preventDefault();
    },
    {
      capture: false,
    }
  );

  canvas.addEventListener(
    'webglcontextrestored',
    (event) => {
      console.log('webglcontextrestored', canvasId);
      if (this.enableReplaceCanvasWhenContextLost) {
        if (canvas.contextLostTimer) {
          clearTimeout(canvas.contextLostTimer);
          canvas.contextLostTimer = null;
          multiview_webgl_monitor(canvasId, true);
        }
      } else {
        this.Restored_From_Context_Lost(canvas, canvas, canvasId);
      }
    },
    {
      capture: false,
    }
  );
};

/**
 * @description update videoRenderArray when recover from context lost
 */
VideoRender.prototype._Replace_Video_Render_Array_From_Context_LOST =
  function ({ canvas, oldCanvas, newDisplayMap }) {
    for (let i = 0; i < this.videoRenderArray.length; i++) {
      if (
        this.videoRenderArray[i].canvas === oldCanvas ||
        this.videoRenderArray[i].canvas === canvas
      ) {
        if (oldCanvas !== canvas) {
          this.videoRenderArray[i].canvas = canvas;
        }
        const fillMode = this.videoRenderArray[i].display.fillMode;
        if (newDisplayMap) {
          this.videoRenderArray[i].display = newDisplayMap.get(
            this.videoRenderArray[i].display.textureindex
          );
        } else {
          this.videoRenderArray[i].display = this._Create_H264_Canvas({
            videoRenderArrayIndex: i,
            canvas,
            canvasId: this.videoRenderArray[i].rendercanvasID,
          });
        }
        if (fillMode && this.videoRenderArray[i].display) {
          this.videoRenderArray[i].display.setFillMode(
            fillMode,
            this.videoRenderArray[i].display.fillModeForResolution
          );
        }
      }
    }
  };

VideoRender.prototype.Restored_From_Context_Lost = function (
  canvas,
  oldCanvas,
  canvasId
) {
  if (this.renderMode) {
    this._Replace_Video_Render_Array_From_Context_LOST({
      canvas,
      oldCanvas,
    });
  } else {
    let newDisplayMap = new Map();
    let localdisplay = new H264bsdCanvas(canvas, 0, undefined, undefined);
    if (!localdisplay.contextGL) {
      create_webgl_context_failed_monitor(canvasId);
    }
    localdisplay.SetMultiView(true);
    newDisplayMap.set(0, localdisplay);

    for (let index = 2; index <= this.threadNumbs; index++) {
      const rDisplay = new H264bsdCanvas(canvas, index, undefined, undefined, {
        program: localdisplay.shaderProgram,
        contextgl: localdisplay.contextGL,
        vBuffer: localdisplay.vertexPosBuffer,
        tBuffer: localdisplay.texturePosBuffer,
        waterMarkTextureRef: localdisplay.waterMarkTextureRef,
        repeatedWaterMarkTextureRef: localdisplay.repeatedWaterMarkTextureRef,
      });
      rDisplay.SetMultiView(true);
      newDisplayMap.set(index, rDisplay);
    }

    let canvasrenderarray = this.renderMGR.get(oldCanvas);
    canvasrenderarray[0] = canvasrenderarray[0].map((display) =>
      newDisplayMap.get(display.textureindex)
    );
    canvasrenderarray[1] = canvasrenderarray[1].map((display) =>
      newDisplayMap.get(display.textureindex)
    );
    if (oldCanvas !== canvas) {
      this.renderMGR.delete(oldCanvas);
      this.renderMGR.set(canvas, canvasrenderarray);
    }

    this.ssrcDispalyMap.forEach((value, key) => {
      value.forEach((frameWrapper) => {
        frameWrapper.haddata = false;
        if (frameWrapper.canvas === oldCanvas) {
          if (oldCanvas !== canvas) {
            frameWrapper.canvas = canvas;
          }
          let oldDisplay = frameWrapper.display;

          frameWrapper.display = newDisplayMap.get(oldDisplay.textureindex);
        }
      });
    });

    this._Replace_Video_Render_Array_From_Context_LOST({
      canvas,
      oldCanvas,
      newDisplayMap,
    });
    if (this.rendingFlag) {
      this.Start_Request_Animation_Frame();
    }
  }
};

/** replace canvas when webgl context lost */
VideoRender.prototype.Replace_Canvas = function (canvas, canvasId) {
  let oldCanvas = null;
  this.bWebglContextLostProtectingMap.forEach((id, canvasItem) => {
    if (id === canvasId) {
      oldCanvas = canvasItem;
      this.bWebglContextLostProtectingMap.delete(canvasItem);
    }
  });
  if (oldCanvas) {
    this.webGLContextLostProtect(canvas, canvasId);
    this.Restored_From_Context_Lost(canvas, oldCanvas, canvasId);
  }
  if (
    this.isSupportOffscreenCanvas &&
    (this.waterMarkCanvas || this.videorendercanvas)
  ) {
    this.waterMarkCanvas = this.videorendercanvas = new OffscreenCanvas(
      640,
      360
    );
  }
};

VideoRender.prototype.Get_Display = function (canvas, canvasId) {
  if (!this.renderMGR) {
    this.renderMGR = new Map();
  }

  this.webGLContextLostProtect(canvas, canvasId);
  let display;

  let renderarray = this.renderMGR.get(canvas);
  if (!renderarray) {
    let unusedArray = [];
    let usedArray = [];
    renderarray = [unusedArray, usedArray];
    this.renderMGR.set(canvas, renderarray);
    let localdisplay = new H264bsdCanvas(canvas, 0, undefined, undefined);
    localdisplay.SetMultiView(true);
    unusedArray.push(localdisplay);
    let index = 2;
    for (; index <= this.threadNumbs; index++) {
      const rDisplay = new H264bsdCanvas(canvas, index, undefined, undefined, {
        program: localdisplay.shaderProgram,
        contextgl: localdisplay.contextGL,
        vBuffer: localdisplay.vertexPosBuffer,
        tBuffer: localdisplay.texturePosBuffer,
        waterMarkTextureRef: localdisplay.waterMarkTextureRef,
        repeatedWaterMarkTextureRef: localdisplay.repeatedWaterMarkTextureRef,
      });
      rDisplay.SetMultiView(true);
      unusedArray.push(rDisplay);
    }
  }
  let canvasrenderarray = this.renderMGR.get(canvas);
  let unusedrenderarray = canvasrenderarray[0];
  let usedrenderarray = canvasrenderarray[1];

  if (canvasrenderarray) {
    if (unusedrenderarray[0]) {
      display = unusedrenderarray.shift();
    }
    usedrenderarray.push(display);
  }

  if (!display) {
    this.Log_Error('No Display obtained from VideoRender.Get_Display');
  }
  return display;
};

VideoRender.prototype.GiveBack_Display = function (data) {
  if (!this.renderMGR) {
    return;
  }

  let obseleteData = data[0];
  if (!obseleteData || !obseleteData.display) {
    return;
  }
  obseleteData.display.SetWaterMarkFlag(0);
  obseleteData.display.SetVideoMode(consts.VIDEO_INVALID);

  let canvasrenderarray = this.renderMGR.get(obseleteData.canvas);
  if (canvasrenderarray) {
    let unusedrenderarray = canvasrenderarray[0];
    let usedrenderarray = canvasrenderarray[1];
    if (usedrenderarray) {
      usedrenderarray.some(function (handle, idx) {
        if (handle === obseleteData.display) {
          usedrenderarray.splice(idx, 1);
          return true;
        }
      });
    }
    unusedrenderarray.push(obseleteData.display);
  }

  return;
};

VideoRender.prototype.Get_Logical_SSrc = function (ssrc) {
  if (ssrc) {
    return (ssrc >> 10) << 10;
  }
  return -1;
};

VideoRender.prototype.Set_LocalVideo_Ptr = function (ptr) {
  if (ptr) {
    this.localVideoPtr = ptr;
  }
  return;
};

VideoRender.prototype.Set_LocalVideo_Buffer = function (sab) {
  if (sab) {
    this.encodedVideoSharedArrayWasmMemory = sab;
    this.sabBuffer = sab.buffer;
    this.encodedVideoSharedArrayBufferUint8Array = new Uint8Array(
      this.sabBuffer
    );
    this.encodedVideoSharedArrayBufferUint16Array = new Uint16Array(
      this.sabBuffer
    );
  }
  return;
};

VideoRender.prototype.Set_LocalVideo_SSRC = function (ssrc) {
  if (ssrc) {
    this.localvideossrc = ssrc;
    this.selfvideossrc = ssrc;
  }
  return;
};

VideoRender.prototype.Draw_LocalVideo = function () {
  if (this.localVideoPtr && this.sabBuffer && this.localvideossrc) {
    if (
      this.encodedVideoSharedArrayWasmMemory &&
      this.encodedVideoSharedArrayWasmMemory.buffer != this.sabBuffer
    ) {
      this.encodedVideoSharedArrayBufferUint8Array = new Uint8Array(
        this.encodedVideoSharedArrayWasmMemory.buffer
      );
      this.encodedVideoSharedArrayBufferUint16Array = new Uint16Array(
        this.encodedVideoSharedArrayWasmMemory.buffer
      );
      this.sabBuffer = this.encodedVideoSharedArrayWasmMemory.buffer;
    }
    let rIndex = Atomics.load(
      this.encodedVideoSharedArrayBufferUint8Array,
      this.localVideoPtr
    );
    let wIndex = Atomics.load(
      this.encodedVideoSharedArrayBufferUint8Array,
      this.localVideoPtr + 1
    );
    let flag = true;
    if (rIndex == wIndex) {
      flag = false;
    }

    if (flag) {
      let length = 0;
      let width =
        this.encodedVideoSharedArrayBufferUint16Array[
          this.localVideoPtr / 2 +
            (4 + (consts.VIDEO_DATA_MAX_SIZE + 4 + 2 * 4 + 2) * rIndex) / 2
        ];
      let height =
        this.encodedVideoSharedArrayBufferUint16Array[
          this.localVideoPtr / 2 +
            (4 + (consts.VIDEO_DATA_MAX_SIZE + 4 + 2 * 4 + 2) * rIndex + 2) / 2
        ];
      //cropping
      let rgnvalid_top =
        this.encodedVideoSharedArrayBufferUint16Array[
          this.localVideoPtr / 2 +
            (4 + (consts.VIDEO_DATA_MAX_SIZE + 4 + 2 * 4 + 2) * rIndex + 4) / 2
        ];
      let rgnvalid_left =
        this.encodedVideoSharedArrayBufferUint16Array[
          this.localVideoPtr / 2 +
            (4 + (consts.VIDEO_DATA_MAX_SIZE + 4 + 2 * 4 + 2) * rIndex + 6) / 2
        ];
      let rgnvalid_width =
        this.encodedVideoSharedArrayBufferUint16Array[
          this.localVideoPtr / 2 +
            (4 + (consts.VIDEO_DATA_MAX_SIZE + 4 + 2 * 4 + 2) * rIndex + 8) / 2
        ];
      let rgnvalid_height =
        this.encodedVideoSharedArrayBufferUint16Array[
          this.localVideoPtr / 2 +
            (4 + (consts.VIDEO_DATA_MAX_SIZE + 4 + 2 * 4 + 2) * rIndex + 10) / 2
        ];

      let mode =
        this.encodedVideoSharedArrayBufferUint16Array[
          this.localVideoPtr / 2 +
            (4 + (consts.VIDEO_DATA_MAX_SIZE + 4 + 2 * 4 + 2) * rIndex + 12) / 2
        ];
      /** mode = VIDEO_BGRA should same as RGBA */
      if (mode && mode !== consts.VIDEO_BGRA) {
        length = (width * height * 3) / 2;
      } else {
        //RGBA
        length = width * height * 4;
      }

      this.croppingParams.top = rgnvalid_top;
      this.croppingParams.left = rgnvalid_left;
      this.croppingParams.height = rgnvalid_height;
      this.croppingParams.width = rgnvalid_width;

      let real_suabaaray =
        this.encodedVideoSharedArrayBufferUint8Array.subarray(
          this.localVideoPtr +
            4 +
            (consts.VIDEO_DATA_MAX_SIZE + 4 + 2 * 4 + 2) * rIndex +
            4 +
            2 * 4,
          this.localVideoPtr +
            4 +
            (consts.VIDEO_DATA_MAX_SIZE + 4 + 2 * 4 + 2) * rIndex +
            4 +
            2 * 4 +
            length
        );
      this.Update_SelfVideo_Texture(
        width,
        height,
        real_suabaaray,
        this.Get_Logical_SSrc(this.localvideossrc),
        mode,
        this.croppingParams
      );
      Atomics.store(
        this.encodedVideoSharedArrayBufferUint8Array,
        this.localVideoPtr,
        (rIndex + 1) % 10
      );
    }
  }
};

VideoRender.prototype.Update_LocalVideo = function (dataframe) {
  let l_ssrc = this.localvideossrc;
  this.Update_RemoteVideo_Texture(dataframe, true, l_ssrc);
};
VideoRender.prototype.Set_Capture_Video_Flag = function (flag) {
  this.startCaptureVideo = flag;
};
VideoRender.prototype.Get_Capture_Video_Flag = function () {
  return this.startCaptureVideo;
};
VideoRender.prototype.JsMediaSDK_VideoRender_interval = function () {
  if (this.startCaptureVideo) {
    this.Draw_LocalVideo();
  }

  if (this.videoRenderArray.length != 0) {
    for (let i = 0; i < this.videoRenderArray.length; i++) {
      const frameWrapper = this.videoRenderArray[i];
      const {
        display,
        canvas,
        enableMultiDecodeVideoWithoutSAB,
        ssrc,
        isMyself,
      } = frameWrapper;
      var video_buf = null;
      if (
        this.Get_Logical_SSrc(ssrc) ===
        this.Get_Logical_SSrc(this.currentactive)
      ) {
        let now = Date.now();
        if (this.jsMediaEngine) {
          let timeData = this.jsMediaEngine.Get_Video_SSRC_Latest_Time();
          this.timestamp = timeData.currentSSRCTime;
          this.audioPlayTime = timeData.audioPlayTime;
        }
        this.calPacingTime(now);
        if (now - this.timestart < this.pacingTime) {
        } else {
          video_buf = this.Get_Decoded_Video_Frame(this.Get_Logical_SSrc(ssrc));
          this.timestart = now;
        }
      } else {
        video_buf = this.Get_Decoded_Video_Frame(this.Get_Logical_SSrc(ssrc));
      }
      if (video_buf) {
        if (
          this.Get_Logical_SSrc(ssrc) ===
          this.Get_Logical_SSrc(this.currentactive)
        ) {
          this.videoTimestamp = video_buf.ntptime;
        }

        let yuvdata;
        if (video_buf.yuvdata instanceof YuvWrap) {
          yuvdata = video_buf.yuvdata.yuvdata;
        } else {
          yuvdata = video_buf.yuvdata;
        }

        this.croppingParams.top = video_buf.r_y;
        this.croppingParams.left = video_buf.r_x;
        this.croppingParams.height = video_buf.r_h;
        this.croppingParams.width = video_buf.r_w;

        const shouldReverse =
          (video_buf.width > video_buf.height &&
            (video_buf.rotation === 1 || video_buf.rotation === 3)) ||
          (video_buf.width < video_buf.height &&
            video_buf.rotation !== 1 &&
            video_buf.rotation !== 3);
        const watermarkWidth = shouldReverse
          ? video_buf.height
          : video_buf.width;
        const watermarkHeight = shouldReverse
          ? video_buf.width
          : video_buf.height;
        const { width: displayWidth, height: displayHeight } = canvas;
        if (
          this.Should_Update_Watermark(
            display,
            watermarkWidth,
            watermarkHeight,
            displayWidth,
            displayHeight
          )
        ) {
          this.Update_Display_Watermark(
            display,
            watermarkWidth,
            watermarkHeight,
            displayWidth,
            displayHeight
          );
        }
        if (
          this.currentVideoHeight != this.croppingParams.height ||
          this.currentVideoWidth != this.croppingParams.width
        ) {
          if (this.Notify_APPUI) {
            this.Notify_APPUI(jsEvent.CURRENT_VIDEO_RESOLUTION, {
              width: this.croppingParams.width,
              height: this.croppingParams.height,
            });
          } else {
            postMessage({
              status: consts.CURRENT_VIDEO_RESOLUTION,
              width: this.croppingParams.width,
              height: this.croppingParams.height,
            });
          }

          this.currentVideoHeight = this.croppingParams.height;
          this.currentVideoWidth = this.croppingParams.width;
        }

        if (
          yuvdata.length == (video_buf.width * video_buf.height * 3) / 2 &&
          video_buf.r_x >= 0 &&
          video_buf.r_y >= 0 &&
          video_buf.r_x + video_buf.r_w <= video_buf.width &&
          video_buf.r_y + video_buf.r_h <= video_buf.height
        ) {
          if (this.vMonitorCount == 14 * 600) {
            if (this.jsMediaEngine) {
              this.jsMediaEngine.Send_Render_Monitor_Log('VDGLM');
            } else {
              postMessage({
                status: consts.VIDEO_RENDER_MONITOR_LOG,
                data: 'VDGLW',
              });
            }
            this.vMonitorCount = 0;
          }
          this.vMonitorCount++;
          if (enableMultiDecodeVideoWithoutSAB) {
            if (display) {
              display.SetVideoMode(consts.VIDEO_I420);
              display.UpdateRemoteVideoTextures(
                video_buf.width,
                video_buf.height,
                this.croppingParams,
                yuvdata,
                video_buf.rotation,
                video_buf.yuv_limited
              );
              // in case firstFrame not coming, rendering wrong
              frameWrapper.firstFrame = true;
            }
          } else {
            frameWrapper.display.SetVideoMode(consts.VIDEO_I420);
            frameWrapper.display.drawNextOuptutPictureGL(
              video_buf.width,
              video_buf.height,
              this.croppingParams,
              yuvdata,
              video_buf.rotation,
              video_buf.yuv_limited,
              frameWrapper
            );
          }
        } else {
          this.Log_Error(
            `Invalid YUV data: ${video_buf.r_x} ${video_buf.r_y} ${video_buf.r_w} ${video_buf.r_h} ${video_buf.width} ${video_buf.height}`
          );
        }

        if (video_buf.yuvdata instanceof YuvWrap) {
          video_buf.yuvdata.recycle();
        }
      }

      if (enableMultiDecodeVideoWithoutSAB && frameWrapper.firstFrame) {
        const { x, y, width, height } = frameWrapper;
        const coordinate = {
          x,
          y,
          width,
          height,
        };
        if (isMyself) {
          display.DrawSelfVideo(coordinate);
        } else {
          display.DrawRemoteVideo(coordinate);
        }
      }
    }
  }
};

VideoRender.prototype.Get_Decoded_Video_Frame = function (ssrc) {
  if (!this.videoQueue) {
    return;
  }
  var ssrc_queue_ = this.videoQueue.GetQueue(ssrc);
  if (ssrc_queue_) {
    var data_node = ssrc_queue_.dequeue();
    return data_node;
  }
  return null;
};

VideoRender.prototype.Set_SSRC_Latest_Time_Stamp = function (timestamp) {
  if (this.timestamp === timestamp || typeof timestamp !== 'number') return;
  this.timestamp = timestamp;
  this.audioPlayTime = Date.now();
};
VideoRender.prototype.Get_Decoded_Video_Buffer_Length = function (ssrc) {
  if (this.videoQueue) {
    return this.videoQueue.GetQueueLength(ssrc);
  }
  return 0;
};
VideoRender.prototype.Get_SSRC_VIDEO_FPS = function (ssrc) {
  ssrc = ssrc >> 10;
  if (this.videoQueue && this.videoQueue.ssrcInfo) {
    var fps = this.videoQueue.ssrcInfo.GetSSRCFpsInfo(ssrc);
    if (fps) {
      return Math.round(fps);
    }
  }
  return 0;
};
VideoRender.prototype.Change_Current_SSRC = function (ssrc) {
  this.currentactive = ssrc;
  this.ClearQueue();
};

VideoRender.prototype.Set_Render_Array_IntervalMode = function (
  videoRenderArray,
  enableMultiDecodeVideoWithoutSAB
) {
  this.videoRenderArray = videoRenderArray;
  if (this.renderMode) {
    if (this.videoRenderArray.length > 0) {
      for (let i = 0; i < this.videoRenderArray.length; i++) {
        const frameWrapper = this.videoRenderArray[i];
        if (enableMultiDecodeVideoWithoutSAB) {
          if (!frameWrapper.display) {
            frameWrapper.enableMultiDecodeVideoWithoutSAB =
              enableMultiDecodeVideoWithoutSAB;
            frameWrapper.display = this.Get_Display(
              frameWrapper.canvas,
              frameWrapper.canvas.id
            );
            if (frameWrapper.display) {
              frameWrapper.display.setFillMode(
                frameWrapper.fillMode,
                frameWrapper.fillModeForResolution
              );
            }
          }
        } else {
          frameWrapper.display = this._Create_H264_Canvas({
            videoRenderArrayIndex: i,
            canvas: frameWrapper.canvas,
            canvasId: frameWrapper.rendercanvasID,
          });
          this.webGLContextLostProtect(
            frameWrapper.canvas,
            frameWrapper.rendercanvasID
          );
        }
      }
    }
  }
};

VideoRender.prototype._Create_H264_Canvas = function ({
  videoRenderArrayIndex,
  canvas,
  canvasId,
}) {
  return new H264bsdCanvas(canvas, 0, undefined, {
    webglcontextlostCallback: this.webglcontextlostCallback.bind(this),
    webglcontextrestoredCallback: this.webglcontextrestoredCallback.bind(this),
    params: {
      videoRenderArrayIndex,
      canvas,
      canvasId,
    },
  });
};

VideoRender.prototype.webglcontextlostCallback = function (
  ev,
  { canvas, canvasId }
) {
  // Not necessary to handle this event
  if (this.renderMode) {
    if (this.enableReplaceCanvasWhenContextLost && canvas && canvasId) {
      if (canvas.contextLostTimer) {
        clearTimeout(canvas.contextLostTimer);
        canvas.contextLostTimer = null;
      }
      canvas.contextLostTimer = setTimeout(() => {
        canvas.contextLostTimer = null;
        multiview_webgl_monitor(canvasId, true);
      }, 3000);
    }
  }
};

VideoRender.prototype.webglcontextrestoredCallback = function (
  ev,
  { videoRenderArrayIndex, canvas, canvasId }
) {
  if (this.renderMode) {
    if (this.enableReplaceCanvasWhenContextLost && canvas && canvasId) {
      if (canvas.contextLostTimer) {
        clearTimeout(canvas.contextLostTimer);
        canvas.contextLostTimer = null;
        multiview_webgl_monitor(canvasId, true);
      }
    } else {
      this.videoRenderArray[videoRenderArrayIndex].display =
        this._Create_H264_Canvas({
          videoRenderArrayIndex,
          canvas,
          canvasId,
        });
    }
  }
};

VideoRender.prototype.ClearQueue = function () {
  try {
    let map = this.videoQueue.ssrcQueueMap;
    for (let [k, v] of map) {
      while (!v.isEmpty()) {
        let item = v.dequeue();
        if (item.yuvdata && item.yuvdata instanceof YuvWrap) {
          item.yuvdata.recycle();
        }
      }
    }
  } catch (e) {
    this.Log_Error('Exception during VideoRender.ClearQueue', e);
  }

  if (this.videoQueue) {
    this.videoQueue.ClearQueue();
  }
  this.currentVideoHeight = 0;
  this.currentVideoWidth = 0;
};

VideoRender.prototype.Set_WaterMark_Info = function ({
  waterMarkCanvas,
  isCreateVideoWaterMark,
  videoWaterMarkName,
  watermarkOpacity,
  watermarkRepeated,
  watermarkPosition,
}) {
  if (waterMarkCanvas) {
    //firefox dont support offscreen canvas
    this.waterMarkCanvas = waterMarkCanvas;
  } else {
    this.waterMarkCanvas = this.videorendercanvas;
  }
  this.isCreateVideoWaterMark = isCreateVideoWaterMark;
  this.videoWaterMarkName = videoWaterMarkName;
  if (watermarkRepeated !== undefined) {
    this.isWaterMarkRepeatedEnable = !!watermarkRepeated;
  }
  if (watermarkOpacity !== undefined) {
    this.waterMarkOpacity = watermarkOpacity;
  }
  if (watermarkPosition !== undefined) {
    this.watermarkPosition = watermarkPosition;
  }
};

VideoRender.prototype.Set_WaterMark_Flag = function (enable) {
  if (this.videoRenderArray.length > 0) {
    for (let i = 0; i < this.videoRenderArray.length; i++) {
      const { display } = this.videoRenderArray[i];
      display.SetWaterMarkFlag(enable ? 1 : 0);
    }
  }
};

VideoRender.prototype.Should_Watermark_Repeated = function (
  displayWidth,
  displayHeight
) {
  return (
    this.isWaterMarkRepeatedEnable && displayWidth > 306 && displayHeight > 202
  );
};

const getHigherQualitySize = function (width, height) {
  if (width < 640 && width) {
    const zoom = 640 / width;
    width = 640;
    height = Math.round(height * zoom);
  }
  return { width, height };
};

VideoRender.prototype.Update_Display_Watermark = function (
  display,
  width,
  height,
  displayWidth,
  displayHeight
) {
  const shouldRepeated = this.Should_Watermark_Repeated(
    displayWidth,
    displayHeight
  );
  if (!this.waterMarkCanvas && !this.videorendercanvas) {
    this.videorendercanvas = this.isSupportOffscreenCanvas
      ? new OffscreenCanvas(640, 360)
      : null;
  }

  /** follow native client, if video size too small, always render watermark in middle */
  const position =
    displayWidth < 512 || displayHeight < 288 ? 16 : this.watermarkPosition;

  const waterMarkCanvas = this.waterMarkCanvas || this.videorendercanvas;

  if (
    typeof OffscreenCanvas === 'function' &&
    waterMarkCanvas instanceof OffscreenCanvas &&
    OffscreenCanvasRenderingContext2D &&
    !OffscreenCanvasRenderingContext2D.prototype.measureText
  ) {
    return;
  }

  const highQualitySize = getHigherQualitySize(width, height);
  width = highQualitySize.width;
  height = highQualitySize.height;

  const watermarkData = shouldRepeated
    ? this.WaterMarkRGBA.Get_Repeated_WaterMarkRGBA({
        canvas: waterMarkCanvas,
        name: this.videoWaterMarkName,
        width,
        height,
        opacity: this.waterMarkOpacity,
        position,
      })
    : this.WaterMarkRGBA.Get_WaterMarkRGBA({
        canvas: waterMarkCanvas,
        name: this.videoWaterMarkName,
        width,
        height,
        opacity: this.waterMarkOpacity,
        position,
      });
  display.updateWatherMark(width, height, watermarkData);
};

/**
 * use displayWidth & displayHeight determine repeated, use width & height draw watermark.
 * Because for firefox sometimes receving video width changes.
 */
VideoRender.prototype.Should_Update_Watermark = function (
  display,
  width,
  height,
  displayWidth,
  displayHeight
) {
  if (!this.isCreateVideoWaterMark) return false;
  let shouldUpdate = false;
  const highQualitySize = getHigherQualitySize(width, height);
  if (
    highQualitySize.width !== display.watermarkWidth ||
    highQualitySize.height !== display.watermarkHeight
  ) {
    shouldUpdate = true;
  }
  const isWatermarkRepeated = this.Should_Watermark_Repeated(
    displayWidth,
    displayHeight
  );
  /** have no watermark yet */
  if (!display.IsSetWaterMark()) {
    shouldUpdate = true;
  }
  /** switch between repeated and no repeated */
  if (isWatermarkRepeated !== display.IsWatermarkRepeated()) {
    shouldUpdate = true;
    display.SetWatermarkRepeated(isWatermarkRepeated);
  }
  if (
    this.waterMarkOpacity &&
    this.waterMarkOpacity !== display.GetWatermarkOpacity()
  ) {
    shouldUpdate = true;
    display.SetWatermarkOpacity(this.waterMarkOpacity);
  }
  /** follow native client, if video size too small, always render watermark in middle */
  const position = width < 512 || height < 288 ? 16 : this.watermarkPosition;
  if (position !== display.GetWatermarkPosition()) {
    shouldUpdate = true;
    display.SetWatermarkPosition(position);
  }
  return shouldUpdate;
};

VideoRender.prototype.Put_Video_Data_Queue = function (
  message,
  maxQuqueBufferLen = 30
) {
  if (this.videoQueue) {
    var ssrcqueue_ = this.videoQueue.GetQueue(message.ssrc);
    if (!ssrcqueue_) {
      ssrcqueue_ = this.videoQueue.AddQueue(message.ssrc);
    }
    ssrcqueue_.enqueue(message);
    if (
      this.Get_Logical_SSrc(this.currentactive) ==
      this.Get_Logical_SSrc(message.ssrc)
    ) {
      if (
        this.Get_Logical_SSrc(this.lastActiveSSRC) !=
        this.Get_Logical_SSrc(message.ssrc)
      ) {
        //clear old buffer if active speaker change
        this.lfTimeStamp = 0;
        this.activeFps = 0;
      }
      this.lastActiveSSRC = this.currentactive;
      if (this.lfTimeStamp) {
        if (this.activeFps) {
          this.activeFps =
            500 / (message.ntptime - this.lfTimeStamp) + this.activeFps / 2;
        } else {
          this.activeFps = 1000 / (message.ntptime - this.lfTimeStamp);
        }
      }
      this.lfTimeStamp = message.ntptime;
    }

    if (this.videoQueue && this.videoQueue.ssrcInfo) {
      var ssrc_node_part = message.ssrc >> 10;
      this.videoQueue.ssrcInfo.UpdateSSRCInfo(ssrc_node_part, message.ntptime);
    }
    var videoqueuelength = this.videoQueue.GetQueueLength(message.ssrc);
    var diff = videoqueuelength - maxQuqueBufferLen;
    while (diff >= 0) {
      let videoBuf = this.Get_Decoded_Video_Frame(message.ssrc);
      if (videoBuf.yuvdata instanceof YuvWrap) {
        videoBuf.yuvdata.recycle();
      }
      diff--;
    }
  } else {
    if (message.yuvdata && message.yuvdata instanceof YuvWrap) {
      message.yuvdata.recycle();
    }
  }
};

VideoRender.prototype.Set_VideoTrackReader = function (
  isSupportVideoTrackReader
) {
  this.isSupportVideoTrackReader = isSupportVideoTrackReader;
};

VideoRender.prototype.Clear_OffScreenCavas = function (canvas) {
  if (this.renderMGR) {
    let renderarray = this.renderMGR.get(canvas);
    if (renderarray) {
      let canvasrenderarray = this.renderMGR.get(canvas);
      if (canvasrenderarray) {
        let unusedrenderarray = canvasrenderarray[0];
        let usedrenderarray = canvasrenderarray[1];
        usedrenderarray.forEach(function (display) {
          display.cleanup();
        });
        unusedrenderarray.forEach(function (display) {
          display.cleanup();
        });
        unusedrenderarray = [];
        usedrenderarray = [];
        this.renderMGR.delete(canvas);
      }
    }
  }
  canvas.width = 1;
  canvas.height = 1;
};

VideoRender.prototype.Zoom_Render = function (data) {};

VideoRender.prototype.Decode_Ssrc = function (ssrc) {
  return (
    this.ssrcDispalyMap.has(this.Get_Logical_SSrc(ssrc)) ||
    this.Get_Logical_SSrc(ssrc) == this.Get_Logical_SSrc(this.selfvideossrc)
  );
};

const isInWorker = typeof window === 'undefined';
VideoRender.prototype.Check_Request_Animation = function () {
  if (
    isInWorker &&
    this.rendingFlag &&
    this.rAFID &&
    this.prevRequestAnimationTimestap
  ) {
    const now = performance.now();
    const duration = now - this.prevRequestAnimationTimestap;
    if (duration > 1000 && !this.rAFIDBack) {
      postMessage({
        status: MONITOR_MESSAGE,
        data: 'WCL_M,RCIF',
      });
      console.log('Request_Animation_stop');
      this.rAFIDBack = setInterval(No_Bindthis_RAF.bind(null, true), 30);
    }
    if (this.rAFIDBack) {
      if (duration >= 1000 * this.retryRequestAnimationStep) {
        if (this.retryRequestAnimationStep <= 32) {
          this.retryRequestAnimationStep *= 2;
        } else {
          this.retryRequestAnimationStep += 30;
        }
        this.Start_Request_Animation_Frame(false, true);
      }
    }
  }
};

VideoRender.prototype.Start_Request_Animation_Frame = function (
  isBackupMode,
  forceRequestAnimation
) {
  if (!isBackupMode && this.rAFIDBack) {
    console.log('Request_Animation_start');
    this.Stop_Request_Animation_Frame_Backup();
  }
  if (this.rAFIDBack) {
    return;
  }
  if (!forceRequestAnimation) {
    this.retryRequestAnimationStep = 2;
  }
  this.prevRequestAnimationTimestap = performance.now();
  this.rAFID = requestAnimationFrame(No_Bindthis_RAF);
  return this.rAFID;
};

VideoRender.prototype.Stop_Request_Animation_Frame_Backup = function () {
  if (this.rAFIDBack) {
    clearInterval(this.rAFIDBack);
    this.rAFIDBack = null;
  }
};

VideoRender.prototype.Stop_Request_Animation_Frame = function () {
  cancelAnimationFrame(this.rAFID);
  this.rAFID = 0;
  this.prevRequestAnimationTimestap = 0;
  this.retryRequestAnimationStep = 2;
  this.Stop_Request_Animation_Frame_Backup();
};

VideoRender.prototype.Log_Error = function (message, errorEvent = null) {
  if (this.globalTracingLogger) {
    // in main thread, log directly
    this.globalTracingLogger.error(message, errorEvent);
  } else {
    // in worker, log via postMessage
    globaltracing_error(message, errorEvent);
  }
};

function No_Bindthis_RAF(isBackupMode) {
  let now = Date.now();
  let that = VideoRenderHandle;
  if (!that.rendingFlag) {
    if (that.rAFID) {
      that.Stop_Request_Animation_Frame();
    }
    return;
  }

  try {
    if (that.startCaptureVideo && that.localVideoPtr) {
      that.Draw_LocalVideo();
    }

    that.ssrcDispalyMap.forEach(function (value, key) {
      let isselfvideo;

      if (
        that.Get_Logical_SSrc(key) == that.Get_Logical_SSrc(that.selfvideossrc)
      ) {
        isselfvideo = true;
      }

      if (
        that.Get_Logical_SSrc(key) === that.Get_Logical_SSrc(that.currentactive)
      ) {
        that.calPacingTime(now);
        if (now - that.timestart < that.pacingTime) {
        } else {
          that.timestart = now;
          let video_buff = that.Get_Decoded_Video_Frame(
            that.Get_Logical_SSrc(key)
          );
          if (video_buff) {
            that.Update_RemoteVideo_Texture(video_buff);
            that.videoTimestamp = video_buff.ntptime;
          }
        }
      } else {
        let video_buff = that.Get_Decoded_Video_Frame(
          that.Get_Logical_SSrc(key)
        );
        if (video_buff) that.Update_RemoteVideo_Texture(video_buff);
      }

      value.forEach((frameWrapper) => {
        if (!frameWrapper.haddata) {
          return;
        }

        let display = frameWrapper.display;
        that.coordinate.x = frameWrapper.x;
        that.coordinate.y = frameWrapper.y;
        that.coordinate.width = frameWrapper.width;
        that.coordinate.height = frameWrapper.height;

        if (display) {
          if (display.GetVideoMode() != consts.VIDEO_INVALID) {
            if (
              isselfvideo &&
              [consts.VIDEO_RGBA, consts.VIDEO_BGRA].indexOf(
                display.GetVideoMode()
              ) !== -1
            ) {
              display.DrawSelfVideo(that.coordinate, false, that.ismirror);
            } else {
              if (isselfvideo) {
                display.DrawRemoteVideo(that.coordinate, that.ismirror);
              } else {
                display.DrawRemoteVideo(that.coordinate);
              }
            }
          }
        }
      });
    });
  } catch (e) {
    that.Log_Error('Exception while rendering video', e);
  }

  that.Start_Request_Animation_Frame(isBackupMode);
}

VideoRender.prototype.calPacingTime = function (now) {
  this.pacingTime = 30;

  if (this.activeFps && this.activeFps > 0 && this.activeFps < 100) {
    this.pacingTime = 1000 / this.activeFps;
  }

  //calculate diff between audio and video
  if (!!this.timestamp && !!this.videoTimestamp) {
    let audioNowTime = this.timestamp + now - this.audioPlayTime;
    let deltaTime = this.videoTimestamp + this.pacingTime - audioNowTime;
    let queueLength = this.videoQueue.GetQueueLength(
      this.Get_Logical_SSrc(this.currentactive)
    );
    if (deltaTime > 65 && deltaTime < 10000 && queueLength >= 1) {
      this.pacingTime = this.pacingTime * 1.5;
    }
    if (deltaTime < -65) {
      this.pacingTime = (this.pacingTime * 1) / 2;
    }
  } else if (!this.timestamp) {
    if (this.pacingTime > 150) {
      this.pacingTime = (this.pacingTime * 1) / 2;
    } else {
      this.pacingTime = this.pacingTime - 10;
    }
  }
};

function set_VideoRenderHandle(handle) {
  if (handle) {
    VideoRenderHandle = handle;
    return true;
  }
  return false;
}

var VideoRenderHandle;
export default VideoRender;
// export default set_VideoRenderHandle;
