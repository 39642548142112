export default {
  SOCKET_CLOSE_TIMEOUT: 4000, // 4000ms
};

/**
 * Some configuration that helps the SDK incrementally enhance
 */
export const CONFIG = {
  IS_SUPPORT_VIDEO_DATACHANNEL: true,
};

export const MONITOR_LOG_DASHBOARD_DATACHANNEL_PARSE_SEPARATOR = '{[WLCCONT]}';
