// this file would be used in web worker
// Notice : safe api in web worker, some js api are not supported in web worker
// Not supported Api Example : localStorage, DomNode, ...
export const ZOOM_CONNECTION_TYPE = {
  ZOOM_CONNECTION_COMMAND: 0,
  ZOOM_CONNECTION_AUDIO: 1,
  ZOOM_CONNECTION_VIDEO: 2,
  ZOOM_CONNECTION_SHARING_JPEG: 3,
  ZOOM_CONNECTION_SHARING_VIDEO: 4,
  ZOOM_CONNECTION_MEDIA_LOG: 5, //FOR DEBUG ONLY
  ZOOM_CONNECTION_SHARING_REMOTE_CONTROL: 6,
  ZOOM_CONNECTION_UNKNOW: 7,
};

/**
 * encode could be videoEncode, audioEncode, sharingEncode ...
 */
export const EncodeDecodeEnum = {
  encode: 1,
  decode: 2,
};

/**
 * integrate with monitor log server
 * in monitor log server 1 means audio, and ...
 * @type {{AUDIO: number, SHARING: number, VIDEO: number}}
 */
export const ConnectionType = {
  AUDIO: 1,
  SHARING: 2,
  VIDEO: 3,
};

/**
 * operation system enums
 * rwg command websocket will return this information, evt is 7937
 */
export const OS_ENUM = {
  UNKNOWN: 0,
  WIN: 1,
  MAC: 2,
  PAD: 3,
  MOBILE: 4,
  CALL_IN: 5,
  LINUX: 6,
  WEB: 7,
  CHROME: 8,
};

/**
 * Example:
 *  md5("videoEnc-zoom") = 27D10C2BC0312F11BE79BF9AA0668220
 *  md5("videoDec-zoom") = D5AC365D95ACF72EE1E8D0FED594C0CD
 */
export const workerStartTypeToDevToolWorkerNameEnum = {
  0: '27D10C2BC0312F11BE79BF9AA0668220',
  1: 'D5AC365D95ACF72EE1E8D0FED594C0CD',
  2: 'AEF36FC5C22CE7AAF5DC05913633EDF7',
  3: 'BD3DF97B715748F6B6A5F59271A2D1A0',
  4: 'D0AEDC804599C31391A946904C74595F',
  5: '65DEAF0AA73F220ED401B90CFFCDFF23',
};

export const WORKER_TYPE = {
  VIDEO_ENCODE: '0',
  VIDEO_DECODE: '1',
  AUDIO_ENCODE: '2',
  AUDIO_DECODE: '3',
  SHARING_ENCODE: '4',
  SHARING_DECODE: '5',
};

/**
 * Previously, the main thread sent to the thread's command is all hard-coded,
 * from 2020-4-15, unified management
 */
export const MAIN_WORKER_COMMAND = {
  SET_USER_NODE_LIST: 'SET_USER_NODE_LIST',
};

export const MessageEnum = {
  CLOSE: 'CLOSE',
  CLOSE_SUCCESS: 'CLOSE_SUCCESS',
  CLOSE_FAIL: 'CLOSE_FAIL',
  IS_CLOSED: 'IS_CLOSED',
  START_SHARING_IE: 'START_SHARING_IE',
  START_SHARING_IE_CALLBACK: 'START_SHARING_IE_CALLBACK',
  SHARING_PARAM_DATA_IE_CALLBACK: 'SHARING_PARAM_DATA_IE_CALLBACK',
  SHARING_MORE_INFO_DATA_IE_CALLBACK: 'SHARING_MORE_INFO_DATA_IE_CALLBACK',
  SHARING_BINARY_CURSOR_DATA_AND_POS_INDICATION_TYPE:
    'SHARING_BINARY_CURSOR_DATA_AND_POS_INDICATION_TYPE',
  SHARING_BINARY_CONTENT_DATA_AND_POS_INDICATION_TYPE:
    'SHARING_BINARY_CONTENT_DATA_AND_POS_INDICATION_TYPE',
};

export const AudioStatus = {
  ComputerAudio_Null: 0,
  ComputerAudio_Connecting: 1,
  ComputerAudio_Connected: 2,

  DesktopAudio_Null: 0,
  DesktopAudio_Connecting: 1,
  DesktopAudio_Connected: 2,
};

export const WorkerLogFileNameMap = {
  [WORKER_TYPE.AUDIO_ENCODE]: 'audio.encode',
  [WORKER_TYPE.AUDIO_DECODE]: 'audio.decode',
  [WORKER_TYPE.VIDEO_ENCODE]: 'video.encode',
  [WORKER_TYPE.VIDEO_DECODE]: 'video.decode',
  [WORKER_TYPE.SHARING_ENCODE]: 'share.encode',
  [WORKER_TYPE.SHARING_DECODE]: 'share.decode',
};
